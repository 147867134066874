/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Select from 'react-select';
import { useSelector } from 'react-redux';
import DateTimePicker from '../../DateTimePicker';

import {
    userServices as listUserServices,
    stateServices,
    listUsers,
    paymentTypes,
} from '../../../redux/selectors';

export default function TransactionForm({ data, register, setValue, errors, control }) {
    const userServicesState = useSelector(listUserServices).userServices;
    const userState = useSelector(listUsers).users;
    const listPaymentTypes = useSelector(paymentTypes).types;

    useEffect(() => {
        async function load() {
            const formatDate = data?.paymentAt ? new Date(data?.paymentAt) : new Date();
            await setValue(
                'userId',
                data?.userId
                    ? { value: data?.User?.id, label: data?.User?.username }
                    : { value: userState[0]?.id, label: userState[0]?.username },
            );
            await setValue(
                'serviceUserId',
                data?.serviceId
                    ? { value: data?.serviceId, label: data?.serviceId }
                    : { value: userServicesState[0]?.id, label: userServicesState[0]?.id },
            );
            await setValue(
                'paymentTypeId',
                data?.paymentTypeId
                    ? { value: data?.payment_type?.id, label: data?.payment_type.name }
                    : { value: listPaymentTypes[0]?.id, label: listPaymentTypes[0]?.name },
            );
            await setValue('total', data?.total || 0.0);
            await setValue('status', data?.status || 'Activa');
            await setValue('payInfo', data?.payInfo || '');
            await setValue('paymentAt', formatDate);
        }
        load();
    }, [data]);

    return (
        <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Usuario
                        </label>
                        <Controller
                            name="userId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={userState.map((v) => ({
                                        value: v.id,
                                        label: v.username,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Servicio
                        </label>
                        <Controller
                            name="serviceUserId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={userServicesState.map((v) => ({
                                        value: v.id,
                                        label: v.id,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Método de pago
                        </label>
                        <Controller
                            name="paymentTypeId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listPaymentTypes.map((v) => ({
                                        value: v.id,
                                        label: v.name,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Total
                        </label>
                        <input
                            type="number"
                            name="total"
                            id="total"
                            autoComplete="total"
                            className="form-input mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('total')}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Estado
                        </label>
                        <select
                            id="status"
                            name="status"
                            className="form-select mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            {...register('status')}
                        >
                            <option value="Activa">Activa</option>
                            <option value="Progreso">Progreso</option>
                            <option value="Finalizada">Finalizada</option>
                            <option value="Cancelada"> Cancelada</option>
                        </select>
                    </div>

                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Información del pago
                        </label>
                        <textarea
                            type="text"
                            name="payInfo"
                            id="payInfo"
                            autoComplete="payInfo"
                            className="form-textarea mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('payInfo')}
                        />
                    </div>
                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Fecha de pago
                        </label>
                        <DateTimePicker name="paymentAt" control={control} />
                    </div>
                </div>
            </div>
        </div>
    );
}

TransactionForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

TransactionForm.defaultProps = {
    data: [],
};
