import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllCities =
    (history = null, limit = 250) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/cities?offset=&limit=${limit}`)
            .then((res) => {
                const { cities } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_CITIES_SUCCESS,
                    payload: {
                        cities,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_CITIES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addCity =
    ({ name, code, status, countriesId, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            code,
            status,
            countriesId,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/cities', data)
            .then((res) => {
                const response = res.data;
                const message = 'Ciudad agregado correctamente';
                history?.push('/location-cities');
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_CITY_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_CITY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteCity =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/cities/${id}`)
            .then((res) => {
                const message = 'Ciudad eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_CITY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_CITY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateCity =
    ({ id, name, code, status, countriesId, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            code,
            status,
            countriesId,
        };
        axiosInstance()
            .put(`/cities/${id}`, newData)
            .then((res) => {
                const message = 'Ciudad actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_CITY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_CITY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
