import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllUserServices =
    (history = null, limit = 1000000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/services_user?offset=&limit=${limit}`)
            .then((res) => {
                const { results } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_USER_SERVICES_SUCCESS,
                    payload: {
                        userServices: results,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_USER_SERVICES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addUserService =
    ({
        userId,
        stateServiceId,
        servicesId,
        value = 0.0,
        description,
        dateService,
        history = null,
        categoriesId,
    }) =>
    (dispatch, prevState) => {
        const images = prevState().userServices?.imagesAdded;
        const data = {
            userID: userId,
            stateServiceId,
            services_id: servicesId,
            value,
            description,
            dateService,
            categoriesId,
            images,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/services_user', data)
            .then((res) => {
                const response = res.data;
                const message = 'User agregado correctamente';
                dispatch(stopLoading());
                history.push('/services-users');
                dispatch(startToast({ message, type: 'success' }));
                dispatch(clearImages());
                return dispatch({
                    type: ActionTypes.ADD_USER_SERVICE_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_USER_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteUserService =
    (id, history = null) =>
    (dispatch) => {
        axiosInstance(history, dispatch)
            .delete(`/services_user/${id}`)
            .then((res) => {
                const message = 'Servicio de usuario eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_USER_SERVICE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_USER_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateUserService =
    ({
        id,
        userId,
        stateServiceId,
        servicesId,
        value = 0.0,
        description,
        dateService,
        categoriesId,
        history = null,
    }) =>
    (dispatch) => {
        const newData = {
            userID: userId,
            stateServiceId,
            services_id: servicesId,
            value,
            description,
            dateService,
            categoriesId,
        };
        axiosInstance()
            .put(`/services_user/${id}`, newData)
            .then((res) => {
                const message = 'Servicio de usuario actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_SERVICE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addImageToServiceUser =
    ({ images, history }) =>
    (dispatch) => {
        if (images?.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const image of images) {
                // eslint-disable-next-line no-undef
                const form = new FormData();
                form.append('myImage', image);
                axiosInstance(history, dispatch)
                    .post('/services_user/addImage', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((res) => {
                        const { link } = res.data;
                        dispatch({
                            type: ActionTypes.ADD_IMAGES_TO_SERVICE,
                            payload: {
                                link,
                            },
                        });
                    })
                    .catch((err) => {
                        console.log('err');
                    });
            }
        }
    };

export const clearImages = () => ({ type: ActionTypes.CLEAR_IMAGES_TO_SERVICE });
