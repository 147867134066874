/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';

import { userServices as listUserServices } from '../../redux/selectors';
import { formatValue } from '../../utils/Utils';
import axiosInstance from '../../helpers/axiosInstance';

export default function UserShowDetails() {
    const params = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const { userServices } = useSelector(listUserServices);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        setData(userServices.filter((service) => service.id === params?.id)[0]);
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/services_user/${params?.id}`)
                .then((res) => setData(res.data))
                .catch(() => console.log());
        };
        load();
    }, []);
    const breadCrumList = [
        {
            name: 'Servicio de usuario',
            url: '/services-users',
        },
        {
            name: data?.User?.username || 'Desconocido',
            url: '/services-users',
        },
    ];
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Usuarios" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Resumen del servicio de usuario
                            </h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Usuario</dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.User?.name}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Tipo de servicio
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.service?.name}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Coste del servicio
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {formatValue(data?.value)}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Categorías
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.Categories?.map((category) => category?.name)?.join(
                                            ' , ',
                                        )}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Descripción
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.description}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Estado del servicio
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.state_service?.name}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de creación
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Date(data?.dateService).toLocaleString()}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Este servicio no se encuentra registrado
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
