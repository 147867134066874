export const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

export const customStyles = {
    headCells: {
        style: {
            padding: '0.5rem',
            whiteSpace: 'nowrap',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: 'rgba(156, 163, 175, 1)',
            backgroundColor: 'rgba(249, 250, 251, 1)',
        },
    },
    cells: {
        style: {
            padding: '0.5rem',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '1.25rem',
        },
    },
    rows: {
        style: {
            borderWidth: '0.5px',
            borderColor: 'rgba(249, 250, 251, 1)',
        },
    },
};
