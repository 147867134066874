import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';
import { handleErrorHttp } from '../../utils/Utils';

export const getAllUsers =
    (history = null, offset = 0, limit = 1000000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/users?offset=${offset}&limit=${limit}&age=&status=`)
            .then((res) => {
                const { results } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_USERS_SUCCESS,
                    payload: {
                        users: results,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_USERS_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addUser =
    ({
        username,
        name,
        lastName,
        email,
        description,
        mobilePhone,
        cityId,
        countryId,
        status,
        password,
        history = null,
        gender = 'M',
        rolId,
        birthday,
    }) =>
    (dispatch) => {
        const data = {
            username,
            name,
            lastName,
            email,
            description,
            mobilePhone,
            coordinates: '0',
            cityId,
            countryId,
            status,
            password,
            gender,
            rolId,
            birthday,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/users', data)
            .then((res) => {
                const response = res.data;
                const message = 'User agregado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                history?.push('/users');
                return dispatch({
                    type: ActionTypes.ADD_USER_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const error = handleErrorHttp(err);
                const message = error;
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_USER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteUser =
    (id, history = null) =>
    (dispatch) => {
        axiosInstance(history, dispatch)
            .delete(`/users/${id}`)
            .then((res) => {
                const message = 'Usuario eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_USER_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_USER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateUser =
    ({
        id,
        username,
        name,
        lastName,
        email,
        description,
        mobilePhone,
        cityId,
        countryId,
        status,
        gender,
        rolId,
        birthday,
    }) =>
    (dispatch) => {
        const newData = {
            id,
            username,
            name,
            lastName,
            email,
            description,
            mobilePhone,
            cityId,
            countryId,
            status,
            gender,
            rolId,
            birthday,
        };
        axiosInstance()
            .put(`/users/admin`, newData)
            .then((res) => {
                const message = 'Usuario actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateUserRole =
    ({ rolesId, userId, history = null }) =>
    (dispatch) => {
        const newData = {
            rolesId,
            userId,
        };
        axiosInstance()
            .put(`/user_roles/`, newData)
            .then((res) => {
                const message = 'Rol actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_USER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
