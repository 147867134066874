import React from 'react';

import './styles.css';

function Welcome(props) {
    const colors = {
        background: '#321850',
        red: '#D70926',
    };
    return (
        <div
            className="w-screen h-screen flex justify-center py-16 xs:pt-44 md:py-28 px-3"
            style={{ backgroundColor: colors.background }}
        >
            <div className="flex flex-col justify-between">
                <h1 className="text-center welcome_header">
                    <p className="welcome_header_text_top">Verificación de</p>
                    <p className="welcome_header_text_bottom">cuenta exitosa</p>
                </h1>
                <p className="welcome_message">
                    Ya puedes acceder con tu usuario y contraseña registrados desde la aplicación
                    movil.
                </p>
                <div className="flex flex-col self-center justify-center items-center">
                    <img src="/logoFULL.svg" alt="Logo de piuts" width={107} height={107} />
                    <div className="flex">
                        <a
                            className="flex welcome_wrap_button"
                            href="https://play.google.com/store/apps/details?id=com.piuts.piuts"
                        >
                            <img src="/playStore.svg" alt="Logo de piuts" width={27} height={30} />
                            <span className="ml-4">
                                <p className="text-white text-xs  md:text-sm">GET IT ON</p>
                                <p className="text-white font-bold text-sm md:text-lg">
                                    Google Play
                                </p>
                            </span>
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="flex welcome_wrap_button ml-6" href="#">
                            <img src="/appStore.svg" alt="Logo de piuts" width={27} height={30} />
                            <span className="ml-4">
                                <p className="text-white text-xs  md:text-sm">Download on the</p>
                                <p className="text-white font-bold text-sm md:text-lg">App Store</p>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
