import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const userServicesDefaultData = {
	id:0,
	userId: 0,
	userMatchId: 0,
	matched: false,
	matchedAt: new Date().toISOString(),
	createdAt: new Date().toISOString()
} */

const initialState = {
    matchs: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_MATCHS_SUCCESS:
            return {
                ...state,
                error: false,
                matchs: [...action.payload.matchs],
            };
        case ActionTypes.LOAD_MATCHS_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_MATCH_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_MATCH_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_MATCH_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_MATCH_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_MATCH_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_MATCH_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
