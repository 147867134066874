/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Select from 'react-select';
import { useSelector } from 'react-redux';
import DateTimePicker from '../../DateTimePicker';

import { services, stateServices, listUsers, categories } from '../../../redux/selectors';

export default function UserServiceForm({
    data,
    register,
    setValue,
    errors,
    control,
    images,
    setImages,
}) {
    const serviceState = useSelector(services).services;
    const userState = useSelector(listUsers).users;
    const listStates = useSelector(stateServices).states;
    const listCategories = useSelector(categories).categories;

    useEffect(() => {
        async function load() {
            const formatDate = data?.dateService ? new Date(data?.dateService) : new Date();
            await setValue(
                'userId',
                data?.userId
                    ? { value: data?.User?.id, label: data?.User?.username }
                    : { value: userState[0]?.id, label: userState[0]?.username },
            );
            await setValue(
                'servicesId',
                data?.services_id
                    ? { value: data?.service?.id, label: data?.service?.name }
                    : { value: serviceState[0]?.id, label: serviceState[0]?.name },
            );
            await setValue(
                'stateServiceId',
                data?.stateServiceId
                    ? { value: data?.state_service?.id, label: data?.state_service?.name }
                    : { value: listStates[0]?.id, label: listStates[0]?.name },
            );
            await setValue('value', data?.value || 0.0);
            await setValue('description', data?.description || '');
            await setValue('dateService', formatDate);
        }
        load();
    }, [data]);

    const handleChangeFiles = (e) => {
        const lenght = e.target.files.length;
        const fls = [];
        // eslint-disable-next-line no-plusplus
        for (let w = 0; w < lenght; w++) {
            fls.push(e.target.files.item(w));
        }
        setImages(fls);
    };

    return (
        <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="user"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Usuario
                        </label>
                        <Controller
                            name="userId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={userState.map((v) => ({
                                        value: v.id,
                                        label: v.username,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                        <label
                            htmlFor="service"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Tipo de servicio
                        </label>
                        <Controller
                            name="servicesId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={serviceState.map((v) => ({
                                        value: v.id,
                                        label: v.name,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                        <label
                            htmlFor="stateService"
                            className="block text-sm font-medium text-gray-700 mt-2 mb-2"
                        >
                            Estado del servicio
                        </label>
                        <Controller
                            name="stateServiceId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listStates.map((v) => ({
                                        value: v.id,
                                        label: v.name,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                        <label
                            htmlFor="categoriesId"
                            className="block text-sm font-medium text-gray-700 mt-2 mb-2"
                        >
                            Categorías
                        </label>
                        <Controller
                            name="categoriesId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={listCategories.map((v) => ({
                                        value: v.id,
                                        label: v.name,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Valor
                        </label>
                        <input
                            type="number"
                            name="value"
                            id="value"
                            autoComplete="value"
                            className="form-input mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('value')}
                        />
                    </div>
                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Imagenes descriptivas
                        </label>
                        <div>
                            <input
                                multiple
                                onChange={handleChangeFiles}
                                type="file"
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Fecha del servicio
                        </label>
                        <DateTimePicker name="dateService" control={control} />
                    </div>
                    <div className="col-span-10 sm:col-span-12">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Descripción
                        </label>
                        <textarea
                            type="text"
                            name="description"
                            id="description"
                            rows={5}
                            autoComplete="description"
                            className="form-textarea mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('description')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
UserServiceForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

UserServiceForm.defaultProps = {
    data: [],
};
