import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

import BreadCrumb from '../../components/BreadCrumb';
import DashBoard from '../../components/DashBoard';
import DataCRUD from '../../components/DataCRUD';
import Search from '../../components/Search';
import { wallets } from '../../redux/selectors';
import { getWalletTransactionPending } from '../../redux/Wallets/wallets.actions';
import { formatValue } from '../../utils/Utils';
import ApprovedModal from './approvedModal';
import RejectModal from './rejectModal';

const columnsModel = [
    {
        name: 'Usuario',
        cell: (row) => (
            <Link
                className="underline text-blue-600"
                to={`/wallets-list/detail/${row?.user?.userWallet?.id}`}
            >
                {' '}
                {row?.user?.name || row?.userId}
            </Link>
        ),
        sortable: true,
    },
    {
        name: 'Monto',
        selector: (row) => formatValue(row?.amount),
        sortable: true,
    },
    {
        name: 'Estado',
        selector: (row) => row?.status,
        sortable: true,
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Transacciones pendientes',
        url: '/wallets-transactions-rejects',
    },
];

function WalletTransactionPending() {
    const [showApproved, setShowApproved] = useState(false);
    const [showRejected, setShowRejected] = useState(false);
    const [transactionId, setTransactionId] = useState(null);
    const TransactionState = useSelector(wallets);
    const TRANSACTIONS = TransactionState.wallets_transactions_pending;
    const [searchData, setSearchData] = useState(TransactionState.wallets_transactions_pending);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            TRANSACTIONS.filter(
                (u) =>
                    u?.user?.username.toLowerCase().includes(text.toLowerCase()) ||
                    u?.services_user?.service?.name.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(TransactionState.wallets);
    }, [TransactionState.wallets]);

    useEffect(() => {
        dispatch(getWalletTransactionPending(history));
    }, [TransactionState.id_log]);

    const openApprovedModal = (id) => {
        setTransactionId(id);
        setShowApproved(true);
    };

    const openRejectModal = (id) => {
        setTransactionId(id);
        setShowRejected(true);
    };

    const customActions = (row) => {
        return (
            <div className="text-lg text-center flex flex-row z-60 absolute">
                <XIcon
                    onClick={() => openRejectModal(row?.id)}
                    className="h-7 w-7 text-red-500 cursor-pointer hover:text-red-300"
                />
                <CheckIcon
                    onClick={() => openApprovedModal(row?.id)}
                    className="h-7 w-7 text-green-600 cursor-pointer hover:text-gray-300"
                />
            </div>
        );
    };

    return (
        <DashBoard>
            <BreadCrumb title="Transacciones" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={TRANSACTIONS}
                />
            </div>
            <DataCRUD
                url="wallets-transactions-pending"
                title="Transacciones pendientes"
                data={searchData}
                columns={columnsModel}
                show
                customActionsComponent={customActions}
            />
            {showApproved && (
                <ApprovedModal
                    show={showApproved}
                    transactionId={transactionId}
                    setShow={setShowApproved}
                />
            )}
            {showRejected && (
                <RejectModal
                    show={showRejected}
                    transactionId={transactionId}
                    setShow={setShowRejected}
                />
            )}
        </DashBoard>
    );
}

export default WalletTransactionPending;
