/* eslint-disable camelcase */
import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';
import { handleErrorHttp } from '../../utils/Utils';

export const getAllCategories =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/categories')
            .then((res) => {
                const { categories } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_CATEGORIES_SUCCESS,
                    payload: {
                        categories,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_CATEGORIES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addCategory =
    ({ name, code, status, history = null, display_order = 1 }) =>
    (dispatch) => {
        const data = {
            name,
            code,
            status,
            display_order,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/categories', data)
            .then((res) => {
                const response = res.data;
                const message = 'Categoría agregado correctamente';
                history?.push('/services-categories');
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_CATEGORY_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const message = handleErrorHttp(err);
                const msg = message || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_CATEGORY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteCategory =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/categories/${id}`)
            .then((res) => {
                const message = 'Categoría eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_CATEGORY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_CATEGORY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateCategory =
    ({ id, name, code, status, display_order = 1, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            code,
            status,
            display_order,
        };
        axiosInstance()
            .put(`/categories/${id}`, newData)
            .then((res) => {
                const message = 'Categoría actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_CATEGORY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_CATEGORY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
