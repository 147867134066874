import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import UserServicesForm from '../../components/Forms/UserServicesForm';
import DeleteForm from './DeleteForm';

import { getAllOffers } from '../../redux/Offers/offers.actions';

import { offers } from '../../redux/selectors';

import { formatValue } from '../../utils/Utils';
import { startLoading } from '../../redux/Loader/loader.actions';

const columnsModel = [
    {
        name: 'Usuario',
        selector: (row) => row?.user.name,
        sortable: true,
    },
    {
        name: 'Servicio',
        selector: (row) => row?.services_user?.service?.name || row?.serviceId,
        sortable: true,
    },
    {
        name: 'Estado',
        cell: (row) => (Number(row?.status) === 1 ? 'Activo' : 'Inactivo'),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => Number(row?.status) === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => Number(row?.status) === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
    {
        name: 'Valor',
        cell: (row) => <td>{formatValue(row?.value)}</td>,
        selector: (row) => row?.value,
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Ofertas',
        url: '/offers',
    },
];

function UserServices() {
    const offerState = useSelector(offers);
    const OFFERS = offerState.offers;
    const [searchData, setSearchData] = useState(offerState.offers);
    const dispatch = useDispatch();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            OFFERS.filter(
                (u) =>
                    u?.user?.username.toLowerCase().includes(text.toLowerCase()) ||
                    u?.services_user?.service?.name.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(offerState.offers);
    }, [offerState.offers]);

    useEffect(() => {
        dispatch(getAllOffers());
    }, [offerState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Ofertas" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={OFFERS}
                />
            </div>
            <DataCRUD
                url="offers"
                title="Ofertas"
                data={searchData}
                columns={columnsModel}
                form={UserServicesForm}
                removeForm={DeleteForm}
                show
            />
        </DashBoard>
    );
}

export default UserServices;
