import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import TransactionForm from '../../components/Forms/TransactionForm';
import DeleteForm from './DeleteForm';

import { getAllTransactions } from '../../redux/Transactions/transactions.actions';

import { transactions } from '../../redux/selectors';

import { formatValue } from '../../utils/Utils';

const columnsModel = [
    {
        name: 'Usuario',
        selector: (row) => row?.User?.name,
        sortable: true,
    },
    {
        name: 'Servicio de usuario',
        selector: (row) => row?.services_user?.service?.name || row?.serviceId,
        sortable: true,
    },
    {
        name: 'Total',
        cell: (row) => <p>{formatValue(row?.total)}</p>,
        selector: (row) => row?.total,
        sortable: true,
    },
    {
        name: 'Metódo de pago',
        selector: (row) => row?.payment_type.name,
        sortable: true,
    },
    {
        name: 'Estado',
        selector: (row) => row?.status,
        sortable: true,
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Transacciones',
        url: '/transactions',
    },
];

function UserServices() {
    const transactionState = useSelector(transactions);
    const TRANSACTIONS = transactionState.transactions;
    const [searchData, setSearchData] = useState(transactionState.transactions);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            TRANSACTIONS.filter(
                (u) =>
                    u?.User?.username.toLowerCase().includes(text.toLowerCase()) ||
                    u?.status.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(transactionState.transactions);
    }, [transactionState.transactions]);

    useEffect(() => {
        dispatch(getAllTransactions(history));
    }, [transactionState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Transacciones" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={TRANSACTIONS}
                />
            </div>
            <DataCRUD
                url="transactions"
                title="Transacciones"
                data={searchData}
                columns={columnsModel}
                form={TransactionForm}
                removeForm={DeleteForm}
                show
            />
        </DashBoard>
    );
}

export default UserServices;
