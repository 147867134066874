import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import UserServicesForm from '../../components/Forms/UserServicesForm';
import DeleteForm from './DeleteForm';

import { getAllUserServices } from '../../redux/UserServices/userServices.actions';

import { userServices } from '../../redux/selectors';

import { formatValue } from '../../utils/Utils';

const columnsModel = [
    {
        name: 'Usuario',
        selector: (row) => row?.User?.name,
        sortable: true,
    },
    {
        name: 'Servicio',
        selector: (row) => row?.service?.name,
        sortable: true,
    },
    {
        name: 'Estado',
        selector: (row) => row?.state_service?.name,
        sortable: true,
    },
    {
        name: 'Valor',
        cell: (row) => <td>{formatValue(row?.value)}</td>,
        selector: (row) => row?.value,
        sortable: true,
    },
    {
        name: 'Fecha del servicio',
        selector: (row) => new Date(row?.dateService).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Servicios de usuarios',
        url: '/services-users',
    },
];

function UserServices() {
    const userServiceState = useSelector(userServices);
    const USER_SERVICES = userServiceState.userServices;
    const [searchData, setSearchData] = useState(userServiceState.userServices);
    const dispatch = useDispatch();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            USER_SERVICES.filter(
                (u) =>
                    u?.User?.username.toLowerCase().includes(text.toLowerCase()) ||
                    u?.service?.name.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(userServiceState.userServices);
    }, [userServiceState.userServices]);

    useEffect(() => {
        dispatch(getAllUserServices());
    }, [userServiceState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Servicios" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={USER_SERVICES}
                />
            </div>
            <DataCRUD
                url="services-users"
                title="Servicios de usuarios"
                data={searchData}
                columns={columnsModel}
                form={UserServicesForm}
                removeForm={DeleteForm}
                show
            />
        </DashBoard>
    );
}

export default UserServices;
