import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

const initialState = {
    collections: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_COLLECTIONS_SUCCESS:
            return {
                ...state,
                error: false,
                collections: [...action.payload.collections],
            };
        case ActionTypes.LOAD_COLLECTIONS_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_COLLECTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_COLLECTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_COLLECTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_COLLECTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_COLLECTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_COLLECTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
