import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const paymentTypesDefaultData = {
	id:0,
	name: '',
	description: '',
	status: false,
	createdAt: new Date().toISOString()
} */

const initialState = {
    types: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PAYMENT_TYPES_SUCCESS:
            return { ...state, error: false, types: [...action.payload.types] };
        case ActionTypes.LOAD_PAYMENT_TYPES_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.ADD_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
