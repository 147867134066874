/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';

import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { focusHandling } from 'cruip-js-toolkit';
import { useSelector } from 'react-redux';
import { auth as authSelector } from '../redux/selectors';

import PrivateRoutes from './PrivateRoutes';

import Login from '../pages/Login';

import Home from '../pages/Home';

import Users from '../pages/Users';
import UserDetail from '../pages/UserDetail';
import UserShowDetails from '../pages/UserShowDetails';

import Cities from '../pages/Cities';
import CityDetail from '../pages/CityDetail';

import Countries from '../pages/Countries';
import CountryDetail from '../pages/CountryDetail';

import Roles from '../pages/Roles';
import RoleDetail from '../pages/RoleDetail';

import Interests from '../pages/Interests';
import InterestDetail from '../pages/InterestDetail';

import Categories from '../pages/Categories';
import CategoriesDetail from '../pages/CategoriesDetail';

import Services from '../pages/Services';
import ServiceDetail from '../pages/ServiceDetail';

import StateServices from '../pages/StateServices';
import StateServiceDetail from '../pages/StateServiceDetail';

import UserServices from '../pages/UserServices';
import UserServiceShowDetails from '../pages/UserServiceShowDetails';

import Matchs from '../pages/Matchs';
import MatchDetail from '../pages/MatchDetail';
import MatchShowDetails from '../pages/MatchShowDetails';

import PaymentTypes from '../pages/PaymentTypes';
import PaymentTypeDetail from '../pages/PaymentTypeDetail';

import Transactions from '../pages/Transactions';
import TransactionDetail from '../pages/TransactionDetail';
import TransactionShowDetails from '../pages/TransactionShowDetails';

import Offers from '../pages/Offers';
import OfferDetail from '../pages/OfferDetail';
import OfferShowDetails from '../pages/OfferShowDetails';

import Wallets from '../pages/Wallets';
import WalletShowDetail from '../pages/WalletShowDetail';

import WalletTransactionApproved from '../pages/WalletTransactionApproved';
import WalletTransactionApprovedDetail from '../pages/WalletTransactionApprovedDetail';

import WalletTransactionPending from '../pages/WalletTransactionPending';
import WalletTransactionPendingDetail from '../pages/WalletTransactionPendingDetail';

import WalletTransactionReject from '../pages/WalletTransactionReject';
import WalletTransactionRejectDetail from '../pages/WalletTransactionRejectDetail';

import Settings from '../pages/Settings';

// APP PUBLIC VIEWS
import Welcome from '../pages/APP-VIEWS/welcome';

import TCviewer from '../pages/T&C';

export default function AppRoutes() {
    const location = useLocation();
    const auth = useSelector(authSelector);

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
        focusHandling('outline');
    }, [location.pathname]); // triggered on route change

    return (
        <Switch>
            <Route exact path="/login">
                <Login auth={auth} />
            </Route>
            <PrivateRoutes component={Home} auth={auth} exact path="/" />
            <PrivateRoutes component={Users} auth={auth} exact path="/users" />
            <PrivateRoutes component={UserShowDetails} auth={auth} exact path="/users/detail/:id" />
            <PrivateRoutes component={UserDetail} auth={auth} path="/users/:id" />
            <PrivateRoutes component={Roles} auth={auth} exact path="/users-roles" />
            <PrivateRoutes component={RoleDetail} auth={auth} exact path="/users-roles/:id" />
            <PrivateRoutes component={Interests} auth={auth} exact path="/interests" />
            <PrivateRoutes component={InterestDetail} auth={auth} exact path="/interests/:id" />
            <PrivateRoutes component={Services} auth={auth} exact path="/services" />
            <PrivateRoutes component={ServiceDetail} auth={auth} exact path="/services/:id" />
            <PrivateRoutes component={UserServices} auth={auth} exact path="/services-users" />
            <PrivateRoutes
                component={UserServiceShowDetails}
                auth={auth}
                exact
                path="/services-users/detail/:id"
            />
            <PrivateRoutes component={Categories} auth={auth} exact path="/services-categories" />
            <PrivateRoutes
                component={CategoriesDetail}
                auth={auth}
                exact
                path="/services-categories/:id"
            />
            <PrivateRoutes component={Matchs} auth={auth} exact path="/matchs" />
            <PrivateRoutes
                component={MatchShowDetails}
                auth={auth}
                exact
                path="/matchs/detail/:id"
            />
            <PrivateRoutes component={MatchDetail} auth={auth} exact path="/matchs/:id" />
            <PrivateRoutes component={PaymentTypes} auth={auth} exact path="/paymentTypes" />
            <PrivateRoutes
                auth={auth}
                exact
                path="/paymentTypes/:id"
                component={PaymentTypeDetail}
            />
            <PrivateRoutes component={Transactions} auth={auth} exact path="/transactions" />
            <PrivateRoutes
                component={TransactionShowDetails}
                auth={auth}
                exact
                path="/transactions/detail/:id"
            />
            <PrivateRoutes component={Offers} auth={auth} exact path="/offers" />
            <PrivateRoutes
                component={OfferShowDetails}
                auth={auth}
                exact
                path="/offers/detail/:id"
            />
            <PrivateRoutes component={OfferDetail} auth={auth} exact path="/offers/:id" />

            <PrivateRoutes component={Wallets} auth={auth} exact path="/wallets-list" />
            <PrivateRoutes
                component={WalletShowDetail}
                auth={auth}
                exact
                path="/wallets-list/detail/:id"
            />

            <PrivateRoutes
                component={WalletTransactionApproved}
                auth={auth}
                exact
                path="/wallets-transactions-approved"
            />
            <PrivateRoutes
                component={WalletTransactionApprovedDetail}
                auth={auth}
                exact
                path="/wallets-transactions-approved/detail/:id"
            />
            <PrivateRoutes
                component={WalletTransactionPending}
                auth={auth}
                exact
                path="/wallets-transactions-pending"
            />
            <PrivateRoutes
                component={WalletTransactionPendingDetail}
                auth={auth}
                exact
                path="/wallets--transactions-pending/detail/:id"
            />

            <PrivateRoutes
                component={WalletTransactionReject}
                auth={auth}
                exact
                path="/wallets-transactions-rejects"
            />
            <PrivateRoutes
                component={WalletTransactionRejectDetail}
                auth={auth}
                exact
                path="/wallets-transactions-rejects/detail/:id"
            />

            <PrivateRoutes component={Settings} auth={auth} exact path="/settings" />
            <PrivateRoutes component={Cities} auth={auth} exact path="/location-cities" />
            <PrivateRoutes component={CityDetail} auth={auth} exact path="/location-cities/:id" />
            <PrivateRoutes component={Countries} auth={auth} exact path="/location-countries" />
            <PrivateRoutes
                component={CountryDetail}
                auth={auth}
                exact
                path="/location-countries/:id"
            />
            <Route exact path="/bienvenido">
                <Welcome />
            </Route>
            <Route exact path="/piuts/terminos-de-uso">
                <div style={{ width: '100vw', height: '100vh' }}>
                    <object
                        style={{ height: '100%', width: '100%' }}
                        data="/TCPiuts.pdf"
                        type="application/pdf"
                    >
                        <p>
                            <a target="_blank" href="/TCPiuts.pdf">
                                Ver pdf
                            </a>
                        </p>
                    </object>
                </div>
            </Route>
            <Route path="*">
                <div>
                    <Redirect to="/" />
                </div>
            </Route>
        </Switch>
    );
}
