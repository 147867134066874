import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

const initialState = {
    users: [],
    error: false,
    message: null,
    id_log: '',
    imagesAdded: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_USERS_SUCCESS:
            return {
                ...state,
                error: false,
                users: [...action.payload.users],
            };
        case ActionTypes.LOAD_USERS_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_USER_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
