import React, { useState, useLayoutEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';

import ServiceForm from '../../components/Forms/ServiceForm';
import axiosInstance from '../../helpers/axiosInstance';
import { addService, updateService, deleteService } from '../../redux/Services/services.actions';
import { auth as authSelector } from '../../redux/selectors';
import { startToast } from '../../redux/Toast/toast.actions';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function ServiceDetail() {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const auth = useSelector(authSelector);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm();

    useLayoutEffect(() => {
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/services/${params?.id}`)
                .then((res) => setData(res.data))
                .catch((err) => console.log());
        };
        load();
    }, []);

    const create = (values) => {
        const { name, description } = values;
        dispatch(addService({ id: 0, name, description, history }));
    };

    const edit = (values) => {
        const { name, description, imageFile, imageUrl } = values;
        const id = data?.id;

        if (imageFile !== null) {
            // eslint-disable-next-line no-undef
            const uploadImage = new FormData();
            uploadImage.set('imageService', imageFile);
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post('/services/addImage', uploadImage, {
                    headers: {
                        Authorization: auth?.user?.token,
                    },
                })
                .then((res) => {
                    const { link } = res.data;
                    dispatch(updateService({ id, name, description, imageUrl: link }));
                    history.push('/services');
                })
                .catch((error) => {
                    dispatch(
                        startToast({ message: 'La imagen no se ha podido subir', type: 'error' }),
                    );
                })
                .finally(() => dispatch(stopLoading()));
            return;
        }

        dispatch(updateService({ id, name, description, imageUrl }));
        history.push('/services');
    };

    const remove = () => {
        const id = data?.id;
        dispatch(deleteService(id));
        history.push('/services');
    };

    const breadCrumList = [
        {
            name: 'Tipo de servicio',
            url: '/services',
        },
        {
            name: data?.name || 'Añadir tipo de servicio',
            url: '/services',
        },
    ];

    return (
        <DashBoard>
            <BreadCrumb title="Servicios" list={breadCrumList} />
            <div className="grid grid-cols-6 flex flex-row shadow overflow-hidden bg-white p-3">
                <div className="w-full  px-4 pt-3 col-span-6 sm:col-span-6">
                    <form onSubmit={data ? handleSubmit(edit) : handleSubmit(create)}>
                        <div className="grid grid-cols-6 flex flex-row">
                            <div className="col-span-6  sm:col-span-5">
                                <ServiceForm
                                    setValue={setValue}
                                    getValue={getValues}
                                    data={data}
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-1 mt-8 ">
                                <div className=" flex flex-col  text-right sm:px-6 px-6">
                                    {data ? (
                                        <>
                                            <button
                                                type="submit"
                                                className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-2 justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-700  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => remove()}
                                            >
                                                Eliminar
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </DashBoard>
    );
}
