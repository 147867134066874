/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';
import { formatValue } from '../../utils/Utils';
import { loader } from '../../redux/selectors';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function WalletShowDetail() {
    const params = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { show: isLoading } = useSelector(loader);

    useLayoutEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/wallet_amount_user/${params?.id}`)
                .then((res) => setData(res.data.wallet))
                .catch((err) => console.log('err'))
                .finally(() => dispatch(stopLoading()));
        };
        load();
    }, []);

    const breadCrumList = [
        {
            name: 'Wallet',
            url: '/wallets-list',
        },
        {
            name: data?.user?.username || 'Desconocido',
            url: '/users',
        },
    ];
    if (isLoading) return <DashBoard />;
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Usuarios" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Resumen e historial de la billetera
                            </h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Usuario</dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Link
                                                className="underline text-blue-500"
                                                to={`/users/detail/${data?.user?.id}`}
                                            >
                                                {data?.user?.username}
                                            </Link>
                                        </dd>
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Balance</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {formatValue(data?.balance)}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Cuenta de ahorros
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.savings_account}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Telefóno</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.mobile_phone}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Disclosure as="div" className="mt-2">
                                            {({ open = true }) => (
                                                <>
                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                                        <span>Transacciones</span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                !open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                            {data?.wallet_user_transactions
                                                                ?.length > 0 ? (
                                                                data?.wallet_user_transactions.map(
                                                                    (value) => (
                                                                        <li
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    `/wallets-transactions-approved/detail/${value?.id}`,
                                                                                )
                                                                            }
                                                                            key={value?.id}
                                                                            className="pl-3 pr-4 hover:bg-gray-200 py-3 flex items-center justify-between text-sm"
                                                                        >
                                                                            <div className="w-0 flex-1 flex items-center">
                                                                                <span className="sm:flex-1  mr-1 truncate">
                                                                                    {
                                                                                        value?.description
                                                                                    }
                                                                                </span>
                                                                                <span
                                                                                    className={`${
                                                                                        value?.amount <
                                                                                        0
                                                                                            ? 'text-red-600'
                                                                                            : 'text-green-600'
                                                                                    }`}
                                                                                >
                                                                                    {formatValue(
                                                                                        value?.amount,
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <div className="ml-4 flex-shrink-0">
                                                                                <EyeIcon
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            `/wallets-transactions-approved/detail/${value?.id}`,
                                                                                        )
                                                                                    }
                                                                                    className="h-7 w-7 cursor-pointer text-indigo-600 hover:text-indigo-500"
                                                                                >
                                                                                    Ver
                                                                                </EyeIcon>
                                                                            </div>
                                                                        </li>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <p className="text-lg text-gray-600 p-2">
                                                                    No hay transacciones
                                                                </p>
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Esta wallet no se encuentra registrada
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
