/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../../helpers/axiosInstance';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';
import { startToast } from '../../redux/Toast/toast.actions';

const PaymentSettings = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [setting, setSetting] = useState(null);

    const {
        register,
        watch,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm({
        commission: 0,
    });

    useEffect(() => {
        axiosInstance(history, dispatch)
            .get('payment_settings')
            .then((res) => setSetting(res.data.settings))
            .catch((err) => console.log());
    }, []);

    useEffect(() => {
        setValue('tbk_api_key', setting?.TBK_API_KEY);
        setValue('tbk_api_key_secret', setting?.TBK_API_KEY_SECRET);
        setValue('commission', setting?.commission);
    }, [setting]);
    console.log(setting);

    const submit = (values) => {
        dispatch(startLoading());
        const data = {
            TBK_API_KEY: values?.tbk_api_key,
            TBK_API_KEY_SECRET: values?.tbk_api_key_secret,
            commission: Number(values?.commission),
        };
        axiosInstance(history, dispatch)
            .put(`payment_settings/${setting?.id}`, data)
            .then((res) => {
                dispatch(
                    startToast({
                        message: 'Configuración de pago actualizada correctamente',
                        type: 'success',
                    }),
                );
                setSetting(res.data?.settings);
            })
            .catch((err) => {
                dispatch(
                    startToast({
                        message: 'Ocurrio un fallo a el actualizar',
                        type: 'error',
                    }),
                );
            })
            .finally(() => dispatch(stopLoading()));
    };
    // 579B532A7440BB0C9079DED94D31EA1615BACEB56610332264630D42D0A36B1C
    // 597055555541
    return (
        <Disclosure defaultOpen>
            {({ open = true }) => (
                <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-sm hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                        <h2 className="text-2xl font-medium text-gray-600">Pago</h2>
                        <ChevronUpIcon
                            className={`${
                                open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-gray-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="shadow-lg p-3 rounded-md">
                        <div className="mb-2 mt-2">
                            <form onSubmit={handleSubmit(submit)} className="ml-3 mt-4">
                                <div className="grid grid-cols-10 gap-2">
                                    <div className="col-span-10 sm:col-span-5">
                                        <label
                                            htmlFor="tbk_api_key"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            TBK_API_KEY
                                        </label>
                                        <input
                                            type="text"
                                            name="tbk_api_key"
                                            id="tbk_api_key"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('tbk_api_key', {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido',
                                                },
                                            })}
                                        />
                                        {errors.tbk_api_key && (
                                            <p className="text-red-700 pt-2  text-xs -mt-2 col-span-10 sm:col-span-10">
                                                {errors.tbk_api_key.message}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-span-10 sm:col-span-5">
                                        <label
                                            htmlFor="tbk_api_key_secret"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            TBK_API_KEY_SECRET
                                        </label>
                                        <input
                                            type="text"
                                            name="tbk_api_key_secret"
                                            id="tbk_api_key_secret"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('tbk_api_key_secret', {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido',
                                                },
                                            })}
                                        />
                                        {errors.tbk_api_key_secret && (
                                            <p className="text-red-700 pt-2  text-xs -mt-2 col-span-10 sm:col-span-10">
                                                {errors.tbk_api_key_secret.message}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-span-10 sm:col-span-5 mt-3">
                                        <label
                                            htmlFor="commission"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Comisión(%)
                                        </label>
                                        <input
                                            type="text"
                                            name="commission"
                                            id="commission"
                                            autoComplete="commission"
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            {...register('commission', {
                                                required: {
                                                    value: true,
                                                    message: 'La comisión es requerida',
                                                },
                                                validate: (value) =>
                                                    (Number(value) <= 100 && Number(value) > 0) ||
                                                    'El porcentaje debe estar entre 0 - 100',
                                            })}
                                            placeholder="40"
                                        />
                                        {errors.commission && (
                                            <p className="text-red-700 pt-2  text-xs -mt-2 col-span-10 sm:col-span-10">
                                                {errors.commission.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary btn-sm bg-green-600 text-white p-2 shadow-md mt-6 w-64"
                                    type="submit"
                                >
                                    Guardar
                                </button>
                            </form>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};
export default PaymentSettings;
