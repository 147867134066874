import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllInterests =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/interest')
            .then((res) => {
                const { interest } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_INTERESTS_SUCCESS,
                    payload: {
                        interests: interest,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_INTERESTS_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addInterest =
    ({ name, description, status, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            description,
            status,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/interest', data)
            .then((res) => {
                const response = res.data;
                const message = 'Interes agregado correctamente';
                history.push('/interests');
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_INTEREST_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_INTEREST_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteInterest =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/interest/${id}`)
            .then((res) => {
                const message = 'Interes eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_INTEREST_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_INTEREST_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateInterest =
    ({ id, name, description, status, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            description,
            status,
        };
        axiosInstance()
            .put(`/interest/${id}`, newData)
            .then((res) => {
                const message = 'Interes actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_INTEREST_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_INTEREST_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
