/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { wallets, auth } from '../../redux/selectors';
import { getWalletTransactionPending } from '../../redux/Wallets/wallets.actions';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const location = useLocation();
    const { pathname } = location;
    const page = pathname.split('/')[1];

    const TransactionState = useSelector(wallets);
    const TRANSACTIONS = TransactionState.wallets_transactions_pending;

    const dispatch = useDispatch();
    const history = useHistory();

    const [showServices, setShowServices] = useState(false);
    const [showUserOptions, setShowUserOptions] = useState(false);
    const [showLocationOptions, setShowLocationOptions] = useState(false);
    const [showInterestOptions, setShowInterestOptions] = useState(false);
    const [showWalletOptions, setShowWalletOptions] = useState(false);

    const trigger = useRef(null);
    const sidebar = useRef(null);

    /* useEffect(() => {
        if (authState?.user?.token) {
            dispatch(getWalletTransactionPending(history));
        }
    }, [TransactionState.id_log, authState?.user]); */

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="lg:w-64">
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
                    sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
                aria-hidden="true"
            />

            {/* Sidebar */}
            <div
                id="sidebar"
                ref={sidebar}
                className={`absolute z-40 left-0 top-0 lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-64'
                }`}
            >
                {/* Sidebar header */}
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    {/* Close button */}
                    <button
                        type="button"
                        ref={trigger}
                        className="lg:hidden text-gray-500 hover:text-gray-400"
                        onClick={() => setSidebarOpen(false)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                        </svg>
                    </button>
                    {/* Logo */}
                    <NavLink exact to="/" className="block">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                            <defs>
                                <linearGradient
                                    x1="28.538%"
                                    y1="20.229%"
                                    x2="100%"
                                    y2="108.156%"
                                    id="logo-a"
                                >
                                    <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                                    <stop stopColor="#A5B4FC" offset="100%" />
                                </linearGradient>
                                <linearGradient
                                    x1="88.638%"
                                    y1="29.267%"
                                    x2="22.42%"
                                    y2="100%"
                                    id="logo-b"
                                >
                                    <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                                    <stop stopColor="#38BDF8" offset="100%" />
                                </linearGradient>
                            </defs>
                            <rect fill="#6366F1" width="32" height="32" rx="16" />
                            <path
                                d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                                fill="#4F46E5"
                            />
                            <path
                                d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                                fill="url(#logo-a)"
                            />
                            <path
                                d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                                fill="url(#logo-b)"
                            />
                        </svg>
                    </NavLink>
                    PIUTS ADMINISTRADOR
                </div>

                {/* Links */}
                <div>
                    <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">Opciones</h3>
                    <ul className="mt-3">
                        {/* Dashboard */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === '' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/"
                                className={`block text-gray-200 hover:text-white transition duration-150 ${
                                    page === '' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                                        <path
                                            className={`fill-current text-gray-400 ${
                                                page === '' && 'text-indigo-500'
                                            }`}
                                            d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                                        />
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === '' && 'text-indigo-600'
                                            }`}
                                            d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                                        />
                                        <path
                                            className={`fill-current text-gray-400 ${
                                                page === '' && 'text-indigo-200'
                                            }`}
                                            d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                                        />
                                    </svg>
                                    <span className="text-sm font-medium">Inicio</span>
                                </div>
                            </NavLink>
                        </li>

                        {/* Users */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page.startsWith('users') && 'bg-gray-900'
                            }`}
                        >
                            <div
                                onClick={() => setShowUserOptions((prev) => !prev)}
                                className={`block cursor-pointer text-gray-200 hover:text-white transition duration-150 ${
                                    page.startsWith('users') && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-grow">
                                        <svg
                                            className="flex-shrink-0 h-6 w-6 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current text-gray-600 ${
                                                    page.startsWith('users') && 'text-indigo-500'
                                                }`}
                                                d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                                            />
                                            <path
                                                className={`fill-current text-gray-400 ${
                                                    page.startsWith('users') && 'text-indigo-300'
                                                }`}
                                                d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium">Usuarios</span>
                                    </div>
                                    {/* Icon */}
                                    <div className="flex flex-shrink-0 ml-2">
                                        <svg
                                            className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                                page.startsWith('users') && 'transform rotate-180'
                                            }`}
                                            viewBox="0 0 12 12"
                                        >
                                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <ul
                                className={`pl-9  p-1  ${
                                    !showUserOptions && !page.startsWith('users') && 'hidden'
                                }`}
                            >
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        onClick={() => setShowUserOptions(true)}
                                        exact
                                        to="/users"
                                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                                            page === 'users' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Listado de usuarios
                                        </span>
                                    </NavLink>
                                </li>
                                <hr className="opacity-25" />
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        onClick={() => setShowUserOptions(true)}
                                        exact
                                        to="/users-roles"
                                        className={`block text-gray-200  hover:text-white transition duration-150" ${
                                            page === 'users-roles' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">Roles</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* interests */}

                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page.startsWith('interests') && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                to="/interests"
                                className={`block cursor-pointer text-gray-200 hover:text-white transition duration-150 ${
                                    page.startsWith('users') && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-grow">
                                        <svg
                                            className="flex-shrink-0 h-6 w-6 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current text-gray-600 ${
                                                    page.startsWith('interests') &&
                                                    'text-indigo-500'
                                                }`}
                                                d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium">Intereses</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        {/* Matchs */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === 'matchs' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/matchs"
                                className={`block text-gray-200 hover:text-white transition duration-150 ${
                                    page === 'matchs' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === 'matchs' && 'text-indigo-500'
                                            }`}
                                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                                        />
                                    </svg>
                                    <span className="text-sm font-medium">Coincidencias</span>
                                </div>
                            </NavLink>
                        </li>
                        {/* Services */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page.startsWith('services') && 'bg-gray-900'
                            }`}
                        >
                            <div
                                onClick={() => setShowServices((prev) => !prev)}
                                className={`block cursor-pointer text-gray-200 hover:text-white transition duration-150 ${
                                    page.startsWith('services') && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-grow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 mr-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                className={`text-gray-600 ${
                                                    page.startsWith('services') && 'text-indigo-500'
                                                }`}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium">Servicios</span>
                                    </div>
                                    {/* Icon */}
                                    <div className="flex flex-shrink-0 ml-2">
                                        <svg
                                            className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                                page.startsWith('services-') &&
                                                'transform rotate-180'
                                            }`}
                                            viewBox="0 0 12 12"
                                        >
                                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <ul
                                className={`pl-9 p-1 ${
                                    !showServices && !page.startsWith('services') && 'hidden'
                                }`}
                            >
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/services-users"
                                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                                            page === 'services-users' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Listar servicios
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/services"
                                        className={`block text-gray-200 hover:text-white transition duration-150" ${
                                            page === 'services' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Tipos de servicios
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/services-categories"
                                        className={`block text-gray-200 hover:text-white transition duration-150" ${
                                            page === 'services-categories' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">Categorías</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* Offers */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === 'offers' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/offers"
                                className={`block text-gray-200 hover:text-white transition duration-150 ${
                                    page === 'offers' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 mr-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            className={`text-gray-600 ${
                                                page === 'offers' && 'text-indigo-500'
                                            }`}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                                        />
                                    </svg>

                                    <span className="text-sm font-medium">Ofertas</span>
                                </div>
                            </NavLink>
                        </li>
                        {/* Wallets */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page.startsWith('wallets') && 'bg-gray-900'
                            }`}
                        >
                            <div
                                onClick={() => setShowWalletOptions((prev) => !prev)}
                                className={`block cursor-pointer text-gray-200 hover:text-white transition duration-150 ${
                                    page.startsWith('services') && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-grow">
                                        <svg
                                            className="h-6 w-6 mr-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 500 500"
                                            stroke="currentColor"
                                            fill={page === 'wallets' ? '#6366f1' : '#FFFFFF15'}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium">Wallet</span>
                                    </div>
                                    {/* Icon */}
                                    <div className="flex flex-shrink-0 ml-2">
                                        <svg
                                            className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                                page.startsWith('wallets-') &&
                                                'transform rotate-180'
                                            }`}
                                            viewBox="0 0 12 12"
                                        >
                                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <ul
                                className={`pl-9 p-1 ${
                                    !showWalletOptions && !page.startsWith('wallets') && 'hidden'
                                }`}
                            >
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/wallets-list"
                                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                                            page === 'wallets-list' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Listar billeteras
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/wallets-transactions-approved"
                                        className={`block text-gray-200 hover:text-white transition duration-150" ${
                                            page === 'wallets-transactions-approved' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Transacciones aprobadas
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        exact
                                        to="/wallets-transactions-rejects"
                                        className={`block text-gray-200 hover:text-white transition duration-150" ${
                                            page === 'wallets-transactions-rejects' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">
                                            Transacciones rechazadas
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2 pr-0">
                                    <NavLink
                                        exact
                                        to="/wallets-transactions-pending"
                                        className={`block text-gray-200 hover:text-white transition duration-150" ${
                                            page === 'wallets-transactions-pending' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium flex justify-center items-center">
                                            <span>Transacciones pendientes</span>
                                            <span className="inline-block flex  py-2.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                                                {TRANSACTIONS?.length || 'N'}
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* Transations */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === 'transactions' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/transactions"
                                className={`block text-gray-200 hover:text-white transition duration-150 ${
                                    page === 'transactions' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 mr-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === 'transactions' && 'text-indigo-500'
                                            }`}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                                        />
                                    </svg>

                                    <span className="text-sm font-medium">Transacciones</span>
                                </div>
                            </NavLink>
                        </li>
                        {/* Payment types */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === 'paymentTypes' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/paymentTypes"
                                className={` block text-gray-200 hover:text-white transition duration-150 ${
                                    page === 'paymentTypes' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === 'paymentTypes' && 'text-indigo-500'
                                            }`}
                                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                        />
                                    </svg>
                                    <span className="text-sm font-medium">Métodos de pago</span>
                                </div>
                            </NavLink>
                        </li>
                        {/* Location */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page.startsWith('location') && 'bg-gray-900'
                            }`}
                        >
                            <div
                                onClick={() => setShowLocationOptions((prev) => !prev)}
                                className={`block cursor-pointer text-gray-200 hover:text-white transition duration-150 ${
                                    page.startsWith('location') && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-grow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 mr-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                className={`text-gray-600 ${
                                                    page.startsWith('location') && 'text-indigo-500'
                                                }`}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium">Localización</span>
                                    </div>
                                    {/* Icon */}
                                    <div className="flex flex-shrink-0 ml-2">
                                        <svg
                                            className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                                page.startsWith('location') &&
                                                'transform rotate-180'
                                            }`}
                                            viewBox="0 0 12 12"
                                        >
                                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <ul
                                className={`pl-9  p-1  ${
                                    !showLocationOptions && !page.startsWith('location') && 'hidden'
                                }`}
                            >
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        onClick={() => setShowLocationOptions(true)}
                                        exact
                                        to="/location-countries"
                                        className={`block text-gray-200 hover:text-white transition duration-150 ${
                                            page === 'location-countries' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">Países</span>
                                    </NavLink>
                                </li>
                                <li className="mb-1 last:mb-0 hover:bg-gray-900 p-2">
                                    <NavLink
                                        onClick={() => setShowLocationOptions(true)}
                                        exact
                                        to="/location-cities"
                                        className={`block text-gray-200  hover:text-white transition duration-150" ${
                                            page === 'location-cities' &&
                                            'text-indigo-400 hover:text-indigo-400'
                                        }`}
                                    >
                                        <span className="text-sm font-medium">Ciudades</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* Settings */}
                        <li
                            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                page === 'settings' && 'bg-gray-900'
                            }`}
                        >
                            <NavLink
                                exact
                                to="/settings"
                                className={` block text-gray-200 hover:text-white transition duration-150 ${
                                    page === 'settings' && 'hover:text-gray-200'
                                }`}
                            >
                                <div className="flex flex-grow">
                                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === 'settings' && 'text-indigo-500'
                                            }`}
                                            d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
                                        />
                                        <path
                                            className={`fill-current text-gray-400 ${
                                                page === 'settings' && 'text-indigo-300'
                                            }`}
                                            d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
                                        />
                                        <path
                                            className={`fill-current text-gray-600 ${
                                                page === 'settings' && 'text-indigo-500'
                                            }`}
                                            d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
                                        />
                                        <path
                                            className={`fill-current text-gray-400 ${
                                                page === 'settings' && 'text-indigo-300'
                                            }`}
                                            d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
                                        />
                                    </svg>
                                    <span className="text-sm font-medium">Configuraciones</span>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
