import { combineReducers } from 'redux';

// reducers
import authReducer from './Auth/auth.reducer';

import userReducer from './Users/users.reducer';
import rolesReducer from './Roles/roles.reducer';
import interestsReducer from './Interests/interests.reducer';
import interestUsersReducer from './InterestUsers/interestUsers.reducer';
import servicesReducer from './Services/services.reducer';
import stateServicesReducer from './StateServices/stateServices.reducer';
import userServicesReducer from './UserServices/userServices.reducer';
import matchsReducer from './Matchs/matchs.reducer';
import paymentTypesReducer from './PaymentTypes/paymentTypes.reducer';
import transactionsReducer from './Transactions/transactions.reducer';
import loaderReducer from './Loader/loader.reducer';
import toastReducer from './Toast/toast.reducer';
import citiesReducer from './Cities/cities.reducer';
import countriesReducer from './Countries/countries.reducer';
import collectionsReducer from './Collections/collections.reducer';
import offersReducer from './Offers/offers.reducer';
import categoriesReducer from './Categories/categories.reducer';
import walletsReducer from './Wallets/wallets.reducer';

const rootReducer = combineReducers({
    listUsers: userReducer,
    rolesUser: rolesReducer,
    interest: interestsReducer,
    services: servicesReducer,
    stateServices: stateServicesReducer,
    userServices: userServicesReducer,
    matchs: matchsReducer,
    paymentTypes: paymentTypesReducer,
    transactions: transactionsReducer,
    auth: authReducer,
    loader: loaderReducer,
    toast: toastReducer,
    cities: citiesReducer,
    countries: countriesReducer,
    interestUsers: interestUsersReducer,
    offers: offersReducer,
    collections: collectionsReducer,
    categories: categoriesReducer,
    wallets: walletsReducer,
});

export default rootReducer;
