import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../css/tailwind.config';

export const tailwindConfig = () => {
    // Tailwind config
    return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
    let r = 0;
    let g = 0;
    let b = 0;
    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }
    return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
    Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumSignificantDigits: 10,
        notation: 'compact',
    }).format(value);

export const generateString = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const handleErrorHttp = (err) => {
    let message = 'Error de conexión';
    if (err?.response?.status === 400) {
        message = err?.response?.data?.message || 'Error de conexión';
        return message;
    }
    if (err?.response?.status === 422) {
        message = err?.response?.data?.message || 'Error de conexión';
        return message;
    }
    return message;
};
