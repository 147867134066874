/* eslint-disable consistent-return */

import axios from 'axios';
import ActionTypes from '../redux/actionTypes';

export default (history = null, dispatch = null) => {
    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {};

    try {
        const persistor = localStorage.getItem('persist:root');
        const { auth } = JSON.parse(persistor);
        const authStorage = JSON.parse(auth);

        if (authStorage?.user?.token) {
            const token = authStorage?.user?.token;
            headers.Authorization = token;
        }
    } catch (e) {
        console.log();
    }

    const axiosInstance = axios.create({
        baseURL,
        headers,
    });
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Content-Type'] = 'application/json';

    axiosInstance.interceptors.request.use((request) => request);

    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            if (error.response.status === 403 || error.response.status === 401) {
                if (dispatch) {
                    dispatch({ type: ActionTypes.LOGOUT_SUCCESS });
                }

                if (history) {
                    history.replace('/login');
                } else {
                    window.location = '/login';
                }
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        },
    );

    return axiosInstance;
};
