/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { countries as listCountries } from '../../../redux/selectors';

export default function CityForm({ data, register, setValue, control, errors }) {
    const { countries } = useSelector(listCountries);
    console.log(countries.filter((c) => c.id === data?.countriesId)[0]?.name);
    useEffect(() => {
        async function load() {
            const countryDefault = data?.countriesId
                ? {
                      value: data?.countriesId,
                      label: countries.filter((c) => c.id === data?.countriesId)[0]?.name,
                  }
                : { value: countries[0]?.id, label: countries[0]?.name };
            await setValue('status', data?.status === 1 ? 'active' : 'inactive');
            await setValue('name', data?.name || '');
            await setValue('code', data?.code || '');
            await setValue('countriesId', countryDefault);
        }
        load();
    }, [data]);

    return (
        <div className="shadow  sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-10 gap-2">
                    <div className="col-span-10 sm:col-span-10">
                        <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                            Código
                        </label>
                        <input
                            type="text"
                            name="code"
                            id="code"
                            autoComplete="code"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('code', {
                                required: {
                                    value: true,
                                    message: 'El código debe tener minimo 1 valor',
                                },
                            })}
                        />
                    </div>
                    {errors.code && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.code.message}
                        </p>
                    )}
                    <div className="col-span-12 sm:col-span-5 mt-1">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            País
                        </label>
                        <Controller
                            name="countriesId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={countries.map((v) => ({
                                        value: v.id,
                                        label: v.name,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-12 sm:col-span-5 mt-1">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 ">
                            Nombre
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="first-name"
                            autoComplete="name"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('name', {
                                minLength: {
                                    value: 3,
                                    message: 'El nombre debe tener minimo 3 valores',
                                },
                            })}
                        />
                    </div>
                    {errors.name && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.name.message}
                        </p>
                    )}
                    <div className="col-span-5 sm:col-span-5 mt-2">
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Estado
                        </label>
                        <select
                            id="status"
                            name="status"
                            autoComplete="status"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            {...register('status')}
                        >
                            <option value="active">Activo</option>
                            <option value="inactive">Inactivo</option>
                        </select>
                    </div>
                    {errors.status && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.status.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

CityForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    control: PropTypes.any.isRequired,
};

CityForm.defaultProps = {
    data: [],
};
