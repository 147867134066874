/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/Auth/auth.actions';

export default function Login({ auth }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = (values) => {
        const { email, password } = values;
        dispatch(login(email, password, history));
    };

    if (auth.isAuthenticated && auth?.user?.token) {
        return <Redirect to="/" />;
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-lg p-10 rounded">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
                        ADMINISTRADOR DE PIUTS
                    </h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-10">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Correo electrónico
                        </label>
                        <input
                            type="email"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido',
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'La dirección de correo electrónico es inválida',
                                },
                            })}
                        />
                    </div>
                    {errors.email && (
                        <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                            {errors.email.message}
                        </p>
                    )}
                    <div className="mt-3">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Contraseña
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            {...register('password', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido',
                                },
                            })}
                        />
                    </div>
                    {errors.password && (
                        <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                            {errors.password.message}
                        </p>
                    )}
                    <div className="mt-3">
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Iniciar sesión
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
