import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const servicesDefaultData = {
	id:0,
	name: '',
	description: '',
	createdAt: new Date().toISOString()
} */

const initialState = {
    services: [],
    error: false,
    message: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SERVICES_SUCCESS:
            return {
                ...state,
                error: false,
                services: [...action.payload.services],
            };
        case ActionTypes.LOAD_SERVICES_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
