import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const transactionsDefaultData = {
	id:0,
	userId: 0,
	serviceId: 0,
    paymentTypeId: 0,
    payInfo: '',
    status: '',
    total: 0.0,	
	createdAt: new Date().toISOString(),
	paymentAt: new Date().toISOString(),
	updated: new Date().toISOString(),
} */

const initialState = {
    transactions: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                error: false,
                transactions: [...action.payload.transactions],
            };
        case ActionTypes.LOAD_TRANSACTIONS_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_TRANSACTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_TRANSACTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_TRANSACTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_TRANSACTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_TRANSACTION_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
