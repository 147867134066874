import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import Search from '../../components/Search';
import BreadCrumb from '../../components/BreadCrumb';

import DataCRUD from '../../components/DataCRUD';
import { cities } from '../../redux/selectors';

import CityForm from '../../components/Forms/CityForm';
import DeleteForm from './DeleteForm';

import { getAllCities } from '../../redux/Cities/cities.actions';
import { getAllCountries } from '../../redux/Countries/countries.actions';

const columnsModel = [
    {
        name: 'Código',
        selector: (row) => row?.code,
        sortable: true,
    },
    {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Estado',
        cell: (row) =>
            row?.status === 1 ? (
                <p data-tag="allowRowEvents">Activo</p>
            ) : (
                <p data-tag="allowRowEvents">Inactivo</p>
            ),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => row.status === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => row.status === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
];

const breadCrumList = [
    {
        name: 'Ciudades',
        url: '/location-cities',
    },
];

export default function Cities() {
    const citieState = useSelector(cities);
    const INTEREST = citieState.cities;
    const [searchData, setSearchData] = useState(citieState.cities);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            prev.filter((u) => u.name.toLowerCase().includes(text.toLowerCase())),
        );
    };

    useEffect(() => {
        setSearchData(citieState.cities);
    }, [citieState.cities]);

    useEffect(() => {
        dispatch(getAllCities(history));
        dispatch(getAllCountries(history));
    }, [citieState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Ciudades" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={INTEREST}
                />
            </div>
            <DataCRUD
                url="location-cities"
                title="Ciudades"
                data={searchData}
                columns={columnsModel}
                form={CityForm}
                removeForm={DeleteForm}
                canCreate
                canDelete
                canEdit
            />
        </DashBoard>
    );
}
