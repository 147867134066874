import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import TransactionForm from '../../components/Forms/TransactionForm';

import { getWalletTransactionRejected } from '../../redux/Wallets/wallets.actions';

import { wallets } from '../../redux/selectors';

import { formatValue } from '../../utils/Utils';

const columnsModel = [
    {
        name: 'Usuario',
        cell: (row) => (
            <Link
                className="underline text-blue-600"
                to={`/wallets-list/detail/${row?.user?.userWallet?.id}`}
            >
                {' '}
                {row?.user?.name || row?.userId}
            </Link>
        ),
        sortable: true,
    },
    {
        name: 'Monto',
        selector: (row) => formatValue(row?.amount),
        sortable: true,
    },
    {
        name: 'Estado',
        selector: (row) => row?.status,
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: (row) => row?.description,
        sortable: true,
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Transacciones rechazadas',
        url: '/wallets-transactions-rejects',
    },
];

function Wallets() {
    const TransactionState = useSelector(wallets);
    const TRANSACTIONS = TransactionState.wallets_transactions_rejects;
    const [searchData, setSearchData] = useState(TransactionState.wallets_transactions_rejects);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            TRANSACTIONS.filter(
                (u) =>
                    u?.user?.username.toLowerCase().includes(text.toLowerCase()) ||
                    u?.services_user?.service?.name.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(TransactionState.wallets);
    }, [TransactionState.wallets]);

    useEffect(() => {
        dispatch(getWalletTransactionRejected(history));
    }, [TransactionState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Transacciones" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={TRANSACTIONS}
                />
            </div>
            <DataCRUD
                url="wallets-transactions-rejects"
                title="Transacciones rechazadas"
                data={searchData}
                columns={columnsModel}
                form={TransactionForm}
                show
            />
        </DashBoard>
    );
}

export default Wallets;
