import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import DashBoard from '../../components/DashBoard';
import WelcomeBanner from '../../components/WelcomeBanner/index';
import DashboardAvatars from '../../partials/dashboard/DashboardAvatars';
import FilterButton from '../../partials/actions/FilterButton';
import Datepicker from '../../partials/actions/Datepicker';

const services = [
    {
        name: 'Usuarios',
        items: [
            {
                name: 'Listar usuarios',
                add_url: '/users/create',
                list_url: '/users',
            },
            {
                name: 'Roles',
                add_url: '/users-roles/create',
                list_url: '/users-roles',
            },
        ],
    },
    {
        name: 'Servicios',
        items: [
            {
                name: 'Servicios',
                // add_url: '/services-users',
                list_url: '/services-users',
            },
            {
                name: 'Tipos de servicios',
                add_url: '/services/create',
                list_url: '/services',
            },
            {
                name: 'Categorías',
                add_url: '/services-categories/create',
                list_url: '/services-categories',
            },
        ],
    },
    {
        name: 'Emparejamientos',
        items: [
            {
                name: 'Coincidencias',
                // add_url: '/matchs',
                list_url: '/matchs',
            },
        ],
    },
    {
        name: 'Transacciones',
        items: [
            {
                name: 'Transacciones',
                // add_url: '/transactions',
                list_url: '/transactions',
            },
        ],
    },
    {
        name: 'Intereses',
        items: [
            {
                name: 'Listar intereses',
                add_url: '/interests/create',
                list_url: '/interests',
            },
        ],
    },
    {
        name: 'Localización',
        items: [
            {
                name: 'Países',
                add_url: '/location-countries/create',
                list_url: '/location-countries',
            },
            {
                name: 'Ciudades',
                add_url: '/location-cities/create',
                list_url: '/location-cities',
            },
        ],
    },
];

function Home() {
    return (
        <DashBoard>
            <WelcomeBanner>
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-1">
                    Bienvenido a el administrador de Piuts 👋
                </h1>
                <p>Somos la siguiente generación en la formalización de citas</p>
            </WelcomeBanner>
            <div className="grid grid-cols-12 gap-6">
                {services.map((service) => (
                    <div
                        style={{ borderTopColor: 'rgba(30, 64, 175,1)' }}
                        className="flex p-3.5 flex-col col-span-full sm:col-span-4 bg-white shadow-lg rounded-md border border-gray-200 border-t-4"
                    >
                        <h3 className="font-bold">{service.name}</h3>
                        <div className="ml-3">
                            {service?.items?.map((item, i) => (
                                <div
                                    className={`flex justify-between mt-5 pt-2 ${
                                        i !== 0 && 'border-t'
                                    }`}
                                >
                                    <span className="font-xs">{item.name}</span>
                                    <div>
                                        <Link
                                            className="ml-2 font-xs text-blue-600 bg-white hover:bg-blue-600 hover:text-white p-2 rounded shadow-sm"
                                            to={item.list_url}
                                        >
                                            Listar
                                        </Link>
                                        {item.add_url && (
                                            <Link
                                                className="p-2 font-xs text-green-500 hover:bg-green-500 hover:text-white "
                                                to={item.add_url}
                                            >
                                                Añadir
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </DashBoard>
    );
}

export default Home;
