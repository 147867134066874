import axiosInstance from '../../helpers/axiosInstance';
import ActionTypes from '../actionTypes';
import { startLoading, stopLoading } from '../Loader/loader.actions';
import { startToast, stopToast } from '../Toast/toast.actions';

export const login =
    (_email, _password, history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        const data = {
            email: _email,
            password: _password,
        };
        axiosInstance(history)
            .post('/auth/signin', data)
            .then((res) => {
                dispatch(stopLoading());
                dispatch(startToast({ message: 'Bienvenido', type: 'success' }));
                const {
                    user: { email, id },
                    token,
                } = res.data;
                return dispatch({
                    type: ActionTypes.LOGIN_SUCCESS,
                    payload: {
                        email,
                        id,
                        token,
                    },
                });
            })
            .catch((err) => {
                const message = 'Credenciales inválidas';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOGIN_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const logout = () => (dispatch) => {
    return dispatch({
        type: ActionTypes.LOGOUT_SUCCESS,
    });
};
