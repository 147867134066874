/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/solid';

export default function ServiceForm({ data, register, setValue, errors, getValue }) {
    const [image, setImage] = useState(null);

    useEffect(() => {
        async function load() {
            await setValue('name', data?.name || '');
            await setValue('description', data?.description || '');
            await setValue('imageUrl', data?.imageUrl || '');
            await setValue('imageFile', null);
        }
        load();
    }, [data]);

    const handleImage = () => {
        // eslint-disable-next-line no-undef
        document.getElementById('input_change_image').click();
    };

    return (
        <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-10 gap-2">
                    <div className="col-span-10 sm:col-span-10">
                        <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Nombre
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'El nombre debe tener minimo 3 valores',
                                },
                            })}
                        />
                    </div>
                    {errors.name && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.name.message}
                        </p>
                    )}
                    <div className="col-span-10 sm:col-span-10">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Description
                        </label>
                        <textarea
                            type="text"
                            name="description"
                            id="description"
                            autoComplete="description"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            {...register('description', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido',
                                },
                                minLength: {
                                    value: 10,
                                    message: 'El nombre debe tener minimo 10 valores',
                                },
                            })}
                        />
                    </div>
                    {errors.description && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.description.message}
                        </p>
                    )}
                    <div className="col-span-10 sm:col-span-10">
                        <label
                            htmlFor="image"
                            className="mt-1 block text-sm font-medium text-gray-700"
                        >
                            Imagen descriptiva
                        </label>
                        {data?.imageUrl.length > 0 ? (
                            <div className="mt-2">
                                <input
                                    title="Añadir imagen"
                                    name="image"
                                    placeholder="Añadir imagen"
                                    type="file"
                                    accept="image/jpeg, image/png, image/jpg, image/webp, image/avif"
                                    style={{ display: 'none' }}
                                    id="input_change_image"
                                    onChange={(e) => {
                                        setImage(e.target.files);
                                        if (e.target.files.length > 0) {
                                            setValue('imageFile', e.target.files[0]);
                                        }
                                    }}
                                />
                                {image ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>{image[0]?.name || 'Desconocido'}</p>
                                        <XIcon
                                            fontSize={10}
                                            className="w-5 h-5 text-gray-500 ml-1 text-red-500 cursor-pointer"
                                            onClick={() => {
                                                setImage(null);
                                                setValue('imageFile', null);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <a
                                        className="text-blue-500"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={data?.imageUrl}
                                    >
                                        {data?.imageUrl}
                                    </a>
                                )}

                                <button
                                    type="button"
                                    className="mt-1 btn bg-green-500 welcome_wrap_button"
                                    onClick={handleImage}
                                >
                                    Cambiar imagen
                                </button>
                            </div>
                        ) : (
                            <input
                                title="Añadir imagen"
                                name="image"
                                placeholder="Añadir imagen"
                                type="file"
                                accept="image/jpeg, image/png, image/jpg, image/webp, image/avif"
                                onChange={(e) => setImage(e.target.files)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

ServiceForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

ServiceForm.defaultProps = {
    data: [],
};
