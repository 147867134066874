import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllWallets =
    (history = null, limit = 1000000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/wallet_amount_user/admin?offset=&limit=${limit}`)
            .then((res) => {
                const { wallet } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_WALLETS_SUCCESS,
                    payload: {
                        wallets: wallet,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_WALLETS_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const getWalletTransactionApproved =
    (history = null, limit = 1000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/wallet_user_transactions?type=APPROVED`)
            .then((res) => {
                const { wallet } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_APPROVED_SUCCESS,
                    payload: {
                        transactions: wallet,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_APPROVED_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const getWalletTransactionRejected =
    (history = null, limit = 1000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/wallet_user_transactions?type=REJECTED`)
            .then((res) => {
                const { wallet } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_REJECT_SUCCESS,
                    payload: {
                        transactions: wallet,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_REJECT_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const getWalletTransactionPending =
    (history = null, limit = 1000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/wallet_user_transactions?type=PENDING`)
            .then((res) => {
                const { wallet } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_PENDING_SUCCESS,
                    payload: {
                        transactions: wallet,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_WALLET_TRASACTIONS_PENDING_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateWalletTransaction =
    ({ id, description, status, closeModal, history = null, limit = 1000 }) =>
    (dispatch) => {
        const data = {
            description,
            status,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .put(`/wallet_user_transactions/${id}`, data)
            .then((res) => {
                dispatch(stopLoading());
                dispatch(
                    startToast({ message: 'Transacción resuelta con éxito', type: 'success' }),
                );
                // eslint-disable-next-line no-unused-expressions
                closeModal && closeModal();
                return dispatch({
                    type: ActionTypes.UPDATE_WALLET_TRASACTIONS_PENDING_SUCCESS,
                    payload: {
                        message: 'Actualizado correctamente',
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.data.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_WALLET_TRASACTIONS_PENDING_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
