export const auth = (state) => state.auth;
export const listUsers = (state) => state.listUsers;
export const roles = (state) => state.rolesUser;
export const interest = (state) => state.interest;
export const interestUsers = (state) => state.interestUsers;
export const services = (state) => state.services;
export const stateServices = (state) => state.stateServices;
export const userServices = (state) => state.userServices;
export const matchs = (state) => state.matchs;
export const paymentTypes = (state) => state.paymentTypes;
export const transactions = (state) => state.transactions;
export const loader = (state) => state.loader;
export const toast = (state) => state.toast;
export const cities = (state) => state.cities;
export const countries = (state) => state.countries;
export const offers = (state) => state.offers;
export const collections = (state) => state.collections;
export const categories = (state) => state.categories;
export const wallets = (state) => state.wallets;
