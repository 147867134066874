import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import TransactionForm from '../../components/Forms/TransactionForm';

import { getAllWallets } from '../../redux/Wallets/wallets.actions';

import { wallets } from '../../redux/selectors';

import { formatValue } from '../../utils/Utils';

const columnsModel = [
    {
        name: 'Usuario',
        selector: (row) => row?.user?.name,
        sortable: true,
    },
    {
        name: 'Balance',
        selector: (row) => formatValue(row?.balance),
        sortable: true,
    },
    {
        name: 'N° de cuenta',
        selector: (row) => row?.savings_account,
        sortable: true,
    },
    {
        name: 'Cantidad de transacciones',
        selector: (row) => row?.wallet_user_transactions?.length,
        sortable: true,
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Wallets',
        url: '/wallets-list',
    },
];

function Wallets() {
    const walletState = useSelector(wallets);
    const WALLETS = walletState.wallets;
    const [searchData, setSearchData] = useState(walletState.wallets);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            prev.filter((u) => u.user?.username.toLowerCase().includes(text.toLowerCase())),
        );
    };

    useEffect(() => {
        setSearchData(walletState.wallets);
    }, [walletState.wallets]);

    useEffect(() => {
        dispatch(getAllWallets(history));
    }, [walletState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Wallets" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={WALLETS}
                />
            </div>
            <DataCRUD
                url="wallets-list"
                detailUrl="wallets-list/detail"
                title="Wallets"
                data={searchData}
                columns={columnsModel}
                form={TransactionForm}
                show
            />
        </DashBoard>
    );
}

export default Wallets;
