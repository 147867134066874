import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const interestsDefaultData = {
	id:0,
	name: '',
	description: '',
	status: false,
	createdAt: new Date().toISOString()
} */

const initialState = {
    interestUsers: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_INTEREST_USERS_SUCCESS:
            return { ...state, error: false, interestUsers: [...action.payload.interestUsers] };
        case ActionTypes.LOAD_INTEREST_USERS_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.ADD_INTEREST_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_INTEREST_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_INTEREST_USER_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_INTEREST_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_INTEREST_USER_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_INTEREST_USER_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
