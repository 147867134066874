import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';

import UserForm from '../../components/Forms/UserForm';
import DeleteForm from './DeleteForm';

import { listUsers } from '../../redux/selectors';
import { getAllUsers } from '../../redux/Users/users.actions';

const columnsModel = [
    {
        name: 'Correo',
        selector: (row) => row?.email,
        sortable: true,
    },
    {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Sexo',
        selector: (row) => (row?.gender === 'M' ? 'Masculino' : 'Femenino'),
        sortable: true,
    },
    {
        name: 'Roles',
        selector: (row) => row?.Roles?.map((rol) => rol?.name).join(', '),
        sortable: true,
    },
    {
        name: 'Estado',
        selector: (row) => (row?.status === 1 ? 'Activo' : 'Inactivo'),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => row.status === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => row.status === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleDateString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Usuarios',
        url: '/users',
    },
];

function Users() {
    const userState = useSelector(listUsers);
    const { users } = userState;
    const [searchData, setSearchData] = useState(userState.users);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            users.filter(
                (u) =>
                    u.email.toLowerCase().includes(text.toLowerCase()) ||
                    u.name.toLowerCase().includes(text.toLowerCase()),
            ),
        );
    };

    useEffect(() => {
        setSearchData(userState.users);
    }, [userState.users]);

    useEffect(() => {
        dispatch(getAllUsers(history));
    }, [userState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Usuarios" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre de usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={users}
                />
            </div>
            <DataCRUD
                url="users"
                title="Usuarios"
                data={searchData}
                columns={columnsModel}
                form={UserForm}
                removeForm={DeleteForm}
                show
                canDelete
                canCreate
                canEdit
            />
        </DashBoard>
    );
}

export default Users;
