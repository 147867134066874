import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllRoles =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/roles/admin')
            .then((res) => {
                const { roles } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_ROLES_SUCCESS,
                    payload: {
                        roles,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_ROLES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addRol =
    ({ name, description, status, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            description,
            status,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/roles', data)
            .then((res) => {
                const response = res.data;
                const message = 'Rol agregado correctamente';
                dispatch(stopLoading());
                history?.push('/users-roles');
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_ROLE_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_ROLE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteRol =
    (id, history = null) =>
    (dispatch) => {
        axiosInstance(history, dispatch)
            .delete(`/roles/${id}`)
            .then((res) => {
                const message = 'Rol eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_ROLE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_ROLE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateRol =
    ({ id, name, description, status, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            description,
            status,
        };
        axiosInstance()
            .put(`/roles/${id}`, newData)
            .then((res) => {
                const message = 'Rol actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_ROLE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_ROLE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
