import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/outline';

export default function BreadCrumb({ title, list = [] }) {
    const generateString = () => Math.random().toString(36).substring(2, 15);
    return (
        <div className="flex flex-row justify-between items-center  h-10 pr-2 pl-2 rounded ">
            <div>
                <p className="text-xl md:text-xl text-gray-800  mb-1 " />
            </div>
            <div className="flex flex-row items-center mr-2">
                <Link to="/" className="text-blue-500">
                    Inicio
                </Link>
                {list.map((value, i) => {
                    return (
                        <React.Fragment key={generateString()}>
                            <p key={generateString()} className="ml-2 mr-2">
                                /
                            </p>
                            {list.length - 1 === i ? (
                                <p key={generateString()} className="text-gray-500">
                                    {value?.name}
                                </p>
                            ) : (
                                <Link
                                    key={generateString()}
                                    disabled
                                    to={value?.url || '/'}
                                    className="text-blue-500"
                                >
                                    {value?.name}
                                </Link>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}

BreadCrumb.propTypes = {
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }))
        .isRequired,
};

BreadCrumb.defaultProps = {
    title: 'Default title',
};
