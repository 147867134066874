import React, { useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

export default function DateTimePicker({ name, control, ...props }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    locale="es"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="Hora"
                    dateFormat={props?.dateFormat || 'MMMM d, yyyy h:mm aa'}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            )}
        />
    );
}
