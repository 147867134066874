import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllMatchs =
    (history = null, limit = 1000000) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/matchs?offset=&limit=${limit}`)
            .then((res) => {
                const { results } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_MATCHS_SUCCESS,
                    payload: {
                        matchs: results,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_MATCHS_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addMatch =
    ({ userId, serviceUserId, matched, matchedAt, history = null }) =>
    (dispatch) => {
        const data = {
            userId,
            serviceUserId,
            matched,
            matchedAt,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/matchs', data)
            .then((res) => {
                const response = res.data;
                const message = 'Coincidencia agregada correctamente';
                history.push('/matchs');
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_MATCH_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_MATCH_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteMatch =
    (id, history = null) =>
    (dispatch) => {
        axiosInstance(history, dispatch)
            .delete(`/matchs/${id}`)
            .then((res) => {
                const message = 'Coincidencia eliminada satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_MATCH_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_MATCH_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateMatch =
    ({ id, userId, serviceUserId, matched, matchedAt, history = null }) =>
    (dispatch) => {
        const newData = {
            userId,
            serviceUserId,
            matched,
            matchedAt,
        };
        axiosInstance()
            .put(`/matchs/${id}`, newData)
            .then((res) => {
                const message = 'Coincidencia actualizada correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_MATCH_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_MATCH_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
