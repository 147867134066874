import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/solid';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import MatchForm from '../../components/Forms/MatchForm';
import axiosInstance from '../../helpers/axiosInstance';

import { addMatch, updateMatch, deleteMatch } from '../../redux/Matchs/matchs.actions';
import { getAllUsers } from '../../redux/Users/users.actions';

export default function RoleDetail() {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        control,
    } = useForm();

    useEffect(() => {
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/matchs/getMatchById/?id=${params?.id}`)
                .then((res) => setData(res.data.match))
                .catch((err) => console.log());
        };
        load();
        dispatch(getAllUsers(history));
    }, []);
    const showStatusMatch = {
        SI: 1,
        NO: 0,
    };

    const create = (values) => {
        const { userId, serviceUserId, matchedAt, matched } = values;
        const statusMatched = showStatusMatch[matched];
        dispatch(
            addMatch({
                userId: userId.value,
                serviceUserId: serviceUserId?.value || '',
                matched: statusMatched,
                matchedAt,
                history,
            }),
        );
    };

    const edit = (values) => {
        const { userId, serviceUserId, matchedAt, matched } = values;

        const id = data?.id || 0;
        const statusMatched = showStatusMatch[matched];
        dispatch(
            updateMatch({
                id,
                userId: userId.value,
                serviceUserId: serviceUserId?.value || '',
                matched: statusMatched,
                matchedAt,
            }),
        );
        // history.push('/matchs');
    };

    const remove = () => {
        const id = data?.id;
        dispatch(deleteMatch(id));
        history.push('/matchs');
    };

    const breadCrumList = [
        {
            name: 'Coincidencias',
            url: '/matchs',
        },
        {
            name: data?.userId || 'Añadir coincidencia',
            url: '/matchs',
        },
    ];

    return (
        <DashBoard>
            <BreadCrumb title="Intereses" list={breadCrumList} />
            <div className="grid grid-cols-6 flex flex-row shadow bg-white p-3">
                <div className="w-full  px-4 pt-3 col-span-6 sm:col-span-6">
                    <form onSubmit={data ? handleSubmit(edit) : handleSubmit(create)}>
                        <div className="grid grid-cols-6 flex flex-row">
                            <div className="col-span-6  sm:col-span-5">
                                <MatchForm
                                    getValue={getValues}
                                    setValue={setValue}
                                    control={control}
                                    data={data}
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-1 mt-8 ">
                                <div className=" flex flex-col  text-right sm:px-6 px-6">
                                    {data ? (
                                        <>
                                            <button
                                                type="submit"
                                                className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-2 justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-700  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => remove()}
                                            >
                                                Eliminar
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </DashBoard>
    );
}
