/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Switch, Route, useLocation, BrowserRouter as Router } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import './css/style.scss';
import './css/styles.css';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

import { useSelector } from 'react-redux';
import { loader } from './redux/selectors';
import AppRoutes from './routes/index';

import Loader from './components/Loader';
import Toast from './components/Toast';

const translateToastTypes = {
    warning: {
        className: 'bg-yellow-400 text-white',
        iconTheme: {
            primary: '#fff',
            secondary: '#FBBF24',
        },
    },
    success: {
        className: 'bg-yellow-400 text-white',
        iconTheme: {
            primary: '#fff',
            secondary: '#FBBF24',
        },
    },
};

function App() {
    const loaderState = useSelector(loader);

    return (
        <Router>
            <div className="relative">
                {loaderState.show && <Loader />}
                <Toast />
                <AppRoutes />
            </div>
        </Router>
    );
}

export default App;
