/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';
import { loader } from '../../redux/selectors';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function UserShowDetails() {
    const params = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { show: isLoading } = useSelector(loader);

    useLayoutEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/users/${params?.id}`)
                .then((res) => setData(res.data.results))
                .catch((err) => console.log('err'))
                .finally(() => dispatch(stopLoading()));
        };
        load();
    }, []);

    const breadCrumList = [
        {
            name: 'Usuarios',
            url: '/users',
        },
        {
            name: data?.username || 'Desconocido',
            url: '/users',
        },
    ];
    if (isLoading) return <DashBoard />;
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Usuarios" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Resumen de usuario
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Información personal e historial
                            </p>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Nombre completo
                                    </dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.name} {data?.lastName}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Correo electrónico
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.email}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Número de telefono
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.mobilePhone}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Estado</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.status === 1 ? (
                                            <span className="text-green-600 text-sm">Activo</span>
                                        ) : (
                                            <span className="text-red-600 text-sm">Inactivo</span>
                                        )}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Sexo</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.gender === 'M' ? 'Masculino' : 'Femenino'}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Altura</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.height} metros
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de cumpleaños
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.birthday &&
                                            new Date(data?.birthday).toLocaleDateString()}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Descripción
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.description}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">País</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.country?.name}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Ciudad</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.city?.name}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Disclosure as="div" className="mt-2">
                                            {({ open = true }) => (
                                                <>
                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                                        <span>Roles</span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                !open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                            {data?.Roles?.length > 0 ? (
                                                                data?.Roles.map((value) => (
                                                                    <li
                                                                        key={value?.id}
                                                                        className="pl-3 pr-4 py-3 flex items-center hover:bg-gray-200 justify-between text-sm"
                                                                    >
                                                                        <div className="w-0 flex-1 sm:items-center  flex flex-col sm:flex-row">
                                                                            <span className="sm:flex-1  mr-1 truncate">
                                                                                {value.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="ml-4 flex-shrink-0">
                                                                            <EyeIcon
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        `/users-roles/${value?.id}`,
                                                                                    )
                                                                                }
                                                                                className="h-7 w-7 cursor-pointer text-indigo-600 hover:text-indigo-500"
                                                                            >
                                                                                Ver
                                                                            </EyeIcon>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <p className="text-lg text-gray-600 p-2">
                                                                    No hay roles
                                                                </p>
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Disclosure as="div" className="mt-2">
                                            {({ open = true }) => (
                                                <>
                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                                        <span>Intereses</span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                !open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                            {data?.Interests?.length > 0 ? (
                                                                data?.Interests.map((value) => (
                                                                    <li
                                                                        key={value?.description}
                                                                        className="pl-3 pr-4 py-3 flex items-center hover:bg-gray-200 justify-between text-sm"
                                                                    >
                                                                        <div className="w-0 flex-1 sm:items-center flex sm:flex-row">
                                                                            <span className="text-gray-700 sm:flex-1 mr-1 truncate">
                                                                                {value?.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="ml-4 flex-shrink-0">
                                                                            <EyeIcon
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        `/interests-users/detail/${value.interest_users.id}`,
                                                                                    )
                                                                                }
                                                                                className="h-7 w-7 font-medium cursor-pointer text-indigo-600 hover:text-indigo-500"
                                                                            >
                                                                                Ver
                                                                            </EyeIcon>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <p className="text-lg text-gray-600 p-2">
                                                                    No hay intereses
                                                                </p>
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Disclosure as="div" className="mt-2">
                                            {({ open = true }) => (
                                                <>
                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                                        <span>Servicios</span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                !open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                            {data?.servicesCreated?.length > 0 ? (
                                                                data?.servicesCreated.map(
                                                                    (value) => (
                                                                        <li
                                                                            key={value?.id}
                                                                            className="pl-3 pr-4 py-3 flex items-center hover:bg-gray-200 justify-between text-sm"
                                                                        >
                                                                            <div className="w-0 flex-1 sm:items-center  flex flex-col sm:flex-row">
                                                                                <span className="sm:flex-1  mr-1 truncate">
                                                                                    {
                                                                                        value?.description
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="ml-4 flex-shrink-0">
                                                                                <EyeIcon
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            `/services-users/detail/${value?.id}`,
                                                                                        )
                                                                                    }
                                                                                    className="h-7 w-7 cursor-pointer text-indigo-600 hover:text-indigo-500"
                                                                                >
                                                                                    Ver
                                                                                </EyeIcon>
                                                                            </div>
                                                                        </li>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <p className="text-lg text-gray-600 p-2">
                                                                    No hay servicios
                                                                </p>
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Disclosure as="div" className="mt-2">
                                            {({ open = true }) => (
                                                <>
                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                                        <span>Transacciones</span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                !open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                            {data?.servicesUser?.length > 0 ? (
                                                                data?.servicesUser.map((value) => (
                                                                    <li
                                                                        key={value?.id}
                                                                        className="pl-3 pr-4 hover:bg-gray-200 py-3 flex items-center justify-between text-sm"
                                                                    >
                                                                        <div className="w-0 flex-1 flex items-center">
                                                                            <span className="sm:flex-1  mr-1 truncate">
                                                                                {value?.description}
                                                                            </span>
                                                                            <span className="">
                                                                                $
                                                                                {
                                                                                    value
                                                                                        ?.transactions
                                                                                        .total
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="ml-4 flex-shrink-0">
                                                                            <EyeIcon
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        `/transactions/detail/${value?.transactions.id}`,
                                                                                    )
                                                                                }
                                                                                className="h-7 w-7 cursor-pointer text-indigo-600 hover:text-indigo-500"
                                                                            >
                                                                                Ver
                                                                            </EyeIcon>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <p className="text-lg text-gray-600 p-2">
                                                                    No hay transacciones
                                                                </p>
                                                            )}
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Este usuario no se encuentra registrado
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
