import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import Search from '../../components/Search';
import BreadCrumb from '../../components/BreadCrumb';

import DataCRUD from '../../components/DataCRUD';
import { paymentTypes } from '../../redux/selectors';

import PaymentTypeForm from '../../components/Forms/PaymentTypeForm';
import DeleteForm from './DeleteForm';

import { getAllPaymentTypes } from '../../redux/PaymentTypes/paymentTypes.actions';

const columnsModel = [
    {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: (row) => row?.description,
        sortable: true,
        wrap: false,
    },
    {
        name: 'Estado',
        cell: (row) =>
            row?.status === 1 ? (
                <p data-tag="allowRowEvents">Activo</p>
            ) : (
                <p data-tag="allowRowEvents">Inactivo</p>
            ),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => row.status === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => row.status === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Metódos de pago',
        url: '/paymentTypes',
    },
];

export default function PaymentTypes() {
    const paymentTypeState = useSelector(paymentTypes);
    const PAYMENT_TYPES = paymentTypeState.types;
    const [searchData, setSearchData] = useState(paymentTypeState.types);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            prev.filter((u) => u.name.toLowerCase().includes(text.toLowerCase())),
        );
    };

    useEffect(() => {
        setSearchData(paymentTypeState.types);
    }, [paymentTypeState.types]);

    useEffect(() => {
        dispatch(getAllPaymentTypes(history));
    }, [paymentTypeState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Métodos de pago" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={PAYMENT_TYPES}
                />
            </div>
            <DataCRUD
                url="paymentTypes"
                title="Métodos de pago"
                data={searchData}
                columns={columnsModel}
                form={PaymentTypeForm}
                removeForm={DeleteForm}
                canDelete
                canEdit
            />
        </DashBoard>
    );
}
