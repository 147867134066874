import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const walletsDefaultData = {
	"id": "022723e3d301743db9d",
    "userId": "a1f7c5f8-e217f9c",
    "credential": "1022440090",
    "savings_account": "525--27",
    "mobile_phone": "+573651764",
    "balance": 84.95,
    "wallet_user_transactions":[],
    "createdAt": "2022-03-17.000Z",
    "updatedAt": "2022-09:38.000Z",
} */

const initialState = {
    wallets: [],
    wallets_transactions_approved: [],
    wallets_transactions_rejects: [],
    wallets_transactions_pending: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_WALLETS_SUCCESS:
            return {
                ...state,
                error: false,
                wallets: [...action.payload.wallets],
            };
        case ActionTypes.LOAD_WALLETS_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_APPROVED_SUCCESS:
            return {
                ...state,
                error: false,
                wallets_transactions_approved: [...action.payload.transactions],
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_APPROVED_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_REJECT_SUCCESS:
            return {
                ...state,
                error: false,
                wallets_transactions_rejects: [...action.payload.transactions],
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_REJECT_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_PENDING_SUCCESS:
            return {
                ...state,
                error: false,
                wallets_transactions_pending: [...action.payload.transactions],
            };
        case ActionTypes.LOAD_WALLET_TRASACTIONS_PENDING_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_WALLET_TRASACTIONS_PENDING_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_WALLET_TRASACTIONS_PENDING_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
