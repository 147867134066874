import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllOffers =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/offers?offset=&limit=1000000')
            .then((res) => {
                const { results } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_OFFERS_SUCCESS,
                    payload: {
                        offers: results,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_OFFERS_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addOffer =
    ({ userId, status, serviceId, value = 0.0, history = null }) =>
    (dispatch) => {
        const data = {
            userId,
            status,
            serviceId,
            value,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/offers', data)
            .then((res) => {
                const response = res.data;
                const message = 'Oferta agregado correctamente';
                dispatch(stopLoading());
                history.push('/offers');
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_OFFER_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_OFFER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteOffer =
    (id, history = null) =>
    (dispatch) => {
        axiosInstance(history, dispatch)
            .delete(`/offers/${id}`)
            .then((res) => {
                const message = 'Oferta eliminada satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_OFFER_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_OFFER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateOffer =
    ({ id, userId, status, serviceId, value = 0.0, history = null }) =>
    (dispatch) => {
        const newData = {
            userId,
            status,
            serviceId,
            value,
        };
        axiosInstance()
            .put(`/offers/${id}`, newData)
            .then((res) => {
                const message = 'Oferta actualizada correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_OFFER_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_OFFER_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
