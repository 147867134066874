/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';

import { getAllUsers } from '../../redux/Users/users.actions';
import { loader } from '../../redux/selectors';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function UserShowDetails() {
    const params = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { show: isLoading } = useSelector(loader);

    useLayoutEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/matchs/getMatchById/?id=${params?.id}`)
                .then((res) => setData(res.data?.match))
                .catch((err) => console.log())
                .finally(() => dispatch(stopLoading()));
        };
        load();
        dispatch(getAllUsers(history));
    }, []);

    const breadCrumList = [
        {
            name: 'Coincidencias',
            url: '/matchs',
        },
        {
            name: data?.serviceMatch ? data?.serviceMatch?.service?.name : 'Desconocido',
            url: '/matchs',
        },
    ];
    if (isLoading) return <DashBoard />;
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Usuarios" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Resumen del emparejamiento
                            </h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Usuario emparejado
                                    </dt>
                                    <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Link
                                            className="underline text-blue-500"
                                            to={`/users/detail/${data?.User?.id}`}
                                        >
                                            {data?.User?.username}
                                        </Link>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Creador del servicio
                                    </dt>
                                    <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Link
                                            className="underline text-blue-500"
                                            to={`/users/detail/${data?.ServiceMatch?.user?.id}`}
                                        >
                                            {data?.ServiceMatch?.user?.username}
                                        </Link>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Servicio</dt>
                                    <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Link
                                            className="underline text-blue-500"
                                            to={`/services-users/detail/${data?.ServiceMatch?.id}`}
                                        >
                                            {data?.ServiceMatch?.service?.name}
                                        </Link>
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Coincidencia
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.matched === 1 ? (
                                            <span className="text-green-600 text-sm">SI</span>
                                        ) : (
                                            <span className="text-red-600 text-sm">NO</span>
                                        )}
                                    </dd>
                                </div>

                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Dirección</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.address}
                                    </dd>
                                </div>

                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de emparejamiento
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Date(data?.matchedAt).toLocaleString()}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de creación
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Date(data?.createdAt).toLocaleString()}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Esta coincidencia no se encuentra registrada
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
