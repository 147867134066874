import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import { TrashIcon, PencilAltIcon, PlusIcon, EyeIcon } from '@heroicons/react/outline';
import { PlusCircleIcon } from '@heroicons/react/solid';

import { paginationComponentOptions, customStyles } from './config';

import Modal from '../Modal';

const renderActions = ({ row, url, remove, isShow, handleShowDetail, canDelete, canEdit }) => {
    const history = useHistory();
    return (
        <div className="text-lg text-center flex flex-row">
            {canEdit && (
                <PencilAltIcon
                    onClick={() => history.push(`/${url}/${row?.id}`)}
                    className="h-7 w-7 text-blue-500 cursor-pointer hover:text-blue-300"
                />
            )}
            {canDelete && (
                <TrashIcon
                    onClick={() => remove(row)}
                    className="h-7 w-7 text-red-500 cursor-pointer hover:text-red-300"
                />
            )}
            {isShow && (
                <EyeIcon
                    onClick={() => history.push(`/${url}/detail/${row?.id}`)}
                    className="h-7 w-7 text-gray-500 cursor-pointer hover:text-gray-300"
                />
            )}
        </div>
    );
};

const renderHeader = (title, url, canCreate) => {
    const history = useHistory();
    return (
        <div className="px-5 py-4 border-b border-gray-100">
            <div className="flex flex-column justify-between">
                <p className="font-semibold text-gray-800 font-sm">{title}</p>
                {canCreate && (
                    <button
                        type="button"
                        onClick={() => history.push(`/${url}/create`)}
                        className="text-blue-500 hover:bg-blue-600 hover:text-white flex justify-center items-center  cursor-pointer p-1 rounded shadow"
                    >
                        <PlusCircleIcon className="h-10 w-10" />
                        <p className="text-sm">Añadir</p>
                    </button>
                )}
            </div>
        </div>
    );
};

export default function DataCRUD({
    url,
    title,
    data,
    columns,
    removeForm,
    detailForm,
    show,
    canDelete,
    canEdit,
    canCreate,
    customActionsComponent,
}) {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const [selectedForm, setSelectedForm] = useState(null);

    const GetRemoveForm = removeForm;
    const GetShowDetailForm = detailForm;

    const handleDelete = (instance) => {
        setSelectedForm(<GetRemoveForm cancel={() => setShowModal(false)} data={instance} />);
        setShowModal((prev) => !prev);
    };

    const handleShowDetail = (instance) => {
        setSelectedForm(<GetShowDetailForm close={() => setShowModal(false)} data={instance} />);
        setShowModal((prev) => !prev);
    };

    const actions = {
        name: 'Acciones',
        cell: (row) =>
            (customActionsComponent && customActionsComponent(row)) ||
            renderActions({
                row,
                url,
                remove: handleDelete,
                isShow: detailForm || show,
                handleShowDetail,
                canDelete,
                canEdit,
            }),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    };

    const columnsWithActions = [...columns, actions];

    return (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
            <DataTable
                title={renderHeader(title, url, canCreate)}
                customStyles={customStyles}
                columns={columnsWithActions}
                data={data}
                highlightOnHover
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[5, 10, 20, 30, 40]}
                noDataComponent={<h4 className="p-4">No hay información disponible</h4>}
                striped
            />
            <Modal show={showModal} closeModal={() => setShowModal(false)}>
                {selectedForm}
            </Modal>
        </div>
    );
}

DataCRUD.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    show: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.obj).isRequired,
    columns: PropTypes.arrayOf(PropTypes.obj).isRequired,
    removeForm: PropTypes.func.isRequired,
    detailForm: PropTypes.func,
    canDelete: PropTypes.bool,
    canEdit: PropTypes.bool,
    canCreate: PropTypes.bool,
    customActionsComponent: PropTypes.func,
};

DataCRUD.defaultProps = {
    detailForm: null,
    show: false,
    canDelete: false,
    canEdit: false,
    canCreate: false,
    customActionsComponent: null,
};
