/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Controller } from 'react-hook-form';

import Select from 'react-select';
import DateTimePicker from '../../DateTimePicker';

import { listUsers, userServices } from '../../../redux/selectors';

export default function MatchForm({ data, register, setValue, errors, control }) {
    const userState = useSelector(listUsers).users;
    const userServicesState = useSelector(userServices).userServices;

    useEffect(() => {
        async function load() {
            const formatDate = data?.matchedAt ? new Date(data?.matchedAt) : new Date();
            await setValue(
                'userId',
                data?.userId
                    ? { value: data?.userId, label: data?.User?.username }
                    : { value: userState[0]?.id, label: userState[0]?.username },
            );
            await setValue(
                'serviceUserId',
                data?.serviceUserId
                    ? { value: data?.serviceUserId, label: data?.serviceUserId }
                    : { value: userServicesState[0]?.id, label: userServicesState[0]?.username },
            );
            await setValue('matched', data?.matched === 1 ? 'SI' : 'NO');
            await setValue('matchedAt', formatDate);
        }
        load();
    }, [data]);

    return (
        <div className="shadow sm:rounded-md  p-3">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 sm:col-span-6">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Usuario
                        </label>
                        <Controller
                            name="userId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={userState.map((v) => ({
                                        value: v.id,
                                        label: v.username,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    {errors.userId && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.userId.message}
                        </p>
                    )}
                    <div className="col-span-12 sm:col-span-6 ">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Servicio
                        </label>
                        <Controller
                            name="serviceUserId"
                            control={control}
                            {...register('serviceUserId', {
                                required: {
                                    values: true,
                                    message: 'El servicio es requerido',
                                },
                            })}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={userServicesState.map((v) => ({
                                        value: v.id,
                                        label: v.id,
                                    }))}
                                    isClearable
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                    {errors.serviceUserId && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.serviceUserId.message}
                        </p>
                    )}

                    <div className="col-span-12 sm:col-span-6 mt-3">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Emparejado
                        </label>
                        <select
                            id="matched"
                            name="matched"
                            autoComplete="matched"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            {...register('matched')}
                        >
                            <option value="SI">Si</option>
                            <option value="NO">No</option>
                        </select>
                    </div>
                    {errors.matched && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.matched.message}
                        </p>
                    )}
                    <br />
                    <div className="col-span-12 sm:col-span-6 mt-3">
                        <label
                            htmlFor="email-address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Fecha del emparejamiento
                        </label>
                        <DateTimePicker name="matchedAt" control={control} />
                    </div>
                    {errors.matchedAt && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.matchedAt.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

MatchForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

MatchForm.defaultProps = {
    data: [],
};
