import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

export default function Modal({ show, closeModal, children }) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity overflow-scroll"
                initialFocus={cancelButtonRef}
                onClose={closeModal}
            >
                <div className="min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 overflow-scroll">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
};
