import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllPaymentTypes =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/payment_types')
            .then((res) => {
                const { payments } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_PAYMENT_TYPES_SUCCESS,
                    payload: {
                        types: payments,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_PAYMENT_TYPES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addPaymentType =
    ({ name, description, status, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            description,
            status,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/payment_types', data)
            .then((res) => {
                const response = res.data;
                const message = 'Metódo de pago agregado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                history?.push('/paymentTypes');
                return dispatch({
                    type: ActionTypes.ADD_PAYMENT_TYPE_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_PAYMENT_TYPE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deletePaymentType =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/payment_types/${id}`)
            .then((res) => {
                const message = 'Metódo de pago eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_PAYMENT_TYPE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_PAYMENT_TYPE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updatePaymentType =
    ({ id, name, description, status, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            description,
            status,
        };
        axiosInstance()
            .put(`/payment_types/${id}`, newData)
            .then((res) => {
                const message = 'Metódo de pago actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_PAYMENT_TYPE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_PAYMENT_TYPE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
