/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Select from 'react-select';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { PlusIcon } from '@heroicons/react/outline';

import { useSelector } from 'react-redux';

import CreateRoleForModal from '../RoleForm/createRoleForModal';
import Modal from '../../Modal';

import {
    roles as listRoles,
    countries as listCountries,
    cities as listCities,
} from '../../../redux/selectors';
import DateTimePicker from '../../DateTimePicker';

export default function UserForm({ data, register, setValue, errors, control, watch }) {
    const { roles } = useSelector(listRoles);
    const { countries } = useSelector(listCountries);
    const { cities } = useSelector(listCities);
    const [showModal, setShowModal] = useState(false);
    const [citiesFiltered, setListCities] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const currentPassword = watch('password');

    const showCreateRoles = () => {
        setSelectedForm(<CreateRoleForModal setShow={setShowModal} />);
        setShowModal(true);
    };

    useEffect(() => {
        if (data?.countryId) {
            setListCities(cities.filter((city) => city.countriesId === data?.countryId));
        } else {
            setListCities(cities.filter((city) => city.countriesId === countries[0]?.id));
        }
    }, [data, cities, countries]);

    useLayoutEffect(() => {
        async function load() {
            const formatDate = data?.birthday ? new Date(data?.birthday) : new Date();
            const countryDefault = data?.country
                ? { value: data?.country?.id, label: data?.country?.name }
                : { value: countries[0]?.id, label: countries[0]?.name };
            const cityDefault = data?.city
                ? { value: data?.city?.id, label: data?.city?.name }
                : { value: cities[0]?.id, label: cities[0]?.name };
            await setValue('username', data?.username || '', {
                shouldValidate: false,
            });
            await setValue('name', data?.name || '', { shouldValidate: false });
            await setValue('email', data?.email || '', { shouldValidate: false });
            await setValue('description', data?.description || '', {
                shouldValidate: false,
            });
            await setValue(
                'rol',
                data?.Roles && { label: data?.Roles[0]?.name, value: data?.Roles[0]?.id },
            );
            await setValue('countries', countryDefault);
            await setValue('cities', cityDefault);
            await setValue('mobilePhone', data?.mobilePhone || '', {
                shouldValidate: false,
            });
            await setValue('status', data?.status === 1 ? 'active' : 'blocked');
            await setValue('gender', data?.gender || 'M');
            await setValue('birthday', formatDate);
        }
        load();
    }, [data]);

    const handleChangeCountry = (e) => {
        setListCities(cities.filter((city) => city.countriesId === e?.value));
    };

    return (
        <div className="shadow  sm:rounded-md">
            <div className="grid grid-cols-6 shadow bg-white p-3">
                <div className="w-ful col-span-6 ">
                    <div className="w-full  mx-auto bg-white rounded-2xl">
                        <Disclosure as="div" className="mt-2" defaultOpen>
                            {({ open = true }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                        <span>Credenciales</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <div className="grid grid-cols-6 gap-6 p-3">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="username"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Nombre de usuario
                                                </label>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    autoComplete="username"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    {...register('username', {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        },
                                                    })}
                                                />
                                                {errors.username && (
                                                    <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                                                        {errors.username.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="email-address"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Correo electrónico
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email-address"
                                                    id="email-address"
                                                    autoComplete="email"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    {...register('email', {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message:
                                                                'La dirección de correo electrónico es inválida',
                                                        },
                                                    })}
                                                />
                                                {errors.email && (
                                                    <p className="text-red-700  text-xs col-span-12">
                                                        {errors.email.message}
                                                    </p>
                                                )}
                                            </div>
                                            {!data && (
                                                <>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label
                                                            htmlFor="password"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Contraseña
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            autoComplete="password"
                                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            {...register('password', {
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        'Este campo es requerido',
                                                                },
                                                            })}
                                                        />
                                                        {errors.password && (
                                                            <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                                                                {errors.password.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <label
                                                            htmlFor="password-confirm"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Confirmar contraseña
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="password-confirm"
                                                            id="password-confirm"
                                                            autoComplete="password-confirm"
                                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            required
                                                            {...register('passwordConfirm', {
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        'Este campo es requerido',
                                                                },
                                                                validate: (v) =>
                                                                    v === currentPassword ||
                                                                    'Las contraseñas no coinciden',
                                                            })}
                                                        />
                                                        {errors.passwordConfirm && (
                                                            <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                                                                {errors.passwordConfirm.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="mt-2 flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                        <span>Información personal</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <div className="grid grid-cols-6 gap-6 p-3">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="first-name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Nombre
                                                </label>
                                                <input
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    autoComplete="first-name"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                    {...register('name', {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="mobilePhone"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Número de telefono
                                                </label>
                                                <input
                                                    type="tel"
                                                    name="mobilePhone"
                                                    id="mobilePhone"
                                                    autoComplete="mobilePhone"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    placeHolder="35 656 566"
                                                    {...register('mobilePhone', {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        },
                                                    })}
                                                />
                                                {errors.mobilePhone && (
                                                    <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                                                        {errors.mobilePhone.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="active"
                                                    className="text-sm font-medium text-gray-700"
                                                >
                                                    Género
                                                </label>
                                                <select
                                                    id="active"
                                                    name="active"
                                                    autoComplete="active"
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    {...register('gender')}
                                                >
                                                    <option value="M">Masculino</option>
                                                    <option value="F">Femenino</option>
                                                    <option value="O">Prefiero no decirlo</option>
                                                </select>
                                            </div>
                                            {errors.gender && (
                                                <p className="text-red-700  text-xs  col-span-10 sm:col-span-10">
                                                    {errors.gender.message}
                                                </p>
                                            )}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="birthday"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Fecha de nacimiento
                                                </label>
                                                <DateTimePicker
                                                    dateFormat="MMMM d, yyyy"
                                                    name="birthday"
                                                    control={control}
                                                />
                                            </div>
                                            {errors.birthday && (
                                                <p className="text-red-700  text-xs  col-span-10 sm:col-span-10">
                                                    {errors.birthday.message}
                                                </p>
                                            )}
                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="email-address"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Descripción
                                                </label>
                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    autoComplete="description"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    {...register('description', {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        },
                                                    })}
                                                />
                                                {errors.description && (
                                                    <p className="text-red-700  text-xs col-span-10 sm:col-span-10">
                                                        {errors.description.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="mt-2">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                        <span>Residencia</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <div className="grid grid-cols-6 gap-6 p-3">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="country"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    País
                                                </label>
                                                <Controller
                                                    name="countries"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            onChange={(value) => {
                                                                setValue('countries', value);
                                                                handleChangeCountry(value);
                                                            }}
                                                            options={countries.map((v) => ({
                                                                value: v.id,
                                                                label: v.name,
                                                            }))}
                                                            isClearable
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                <label
                                                    htmlFor="city"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Ciudad
                                                </label>
                                                <Controller
                                                    name="cities"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={citiesFiltered.map((v) => ({
                                                                value: v.id,
                                                                label: v.name,
                                                            }))}
                                                            isClearable
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="mt-2">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium leading-4 text-gray-800 bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-900 focus-visible:ring-opacity-75 shadow-md">
                                        <span>Roles y permisos</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                        <div className="grid grid-cols-6 gap-6 p-3">
                                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                                <label
                                                    htmlFor="rol"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Roles
                                                </label>
                                                <Controller
                                                    name="rol"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={roles.map((v) => ({
                                                                value: v.id,
                                                                label: v.name,
                                                            }))}
                                                            isClearable
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="active"
                                                    className="text-sm font-medium text-gray-700"
                                                >
                                                    Estado
                                                </label>
                                                <select
                                                    id="active"
                                                    name="active"
                                                    autoComplete="active"
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    {...register('status')}
                                                >
                                                    <option
                                                        value="active"
                                                        className="text-green-700"
                                                    >
                                                        Activo
                                                    </option>
                                                    <option
                                                        value="blocked"
                                                        className="text-red-700"
                                                    >
                                                        Bloqueado
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>
                </div>
            </div>
            <Modal show={showModal} closeModal={() => setShowModal(false)}>
                {selectedForm}
            </Modal>
        </div>
    );
}

UserForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

UserForm.defaultProps = {
    data: [],
};
