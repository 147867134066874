import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';

import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';
import {
    addCategory,
    updateCategory,
    deleteCategory,
} from '../../redux/Categories/categories.actions';
import CategoryForm from '../../components/Forms/CategoryForm';

export default function InterestDetail() {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm();

    const showStatus = {
        active: 1,
        inactive: 0,
    };

    useEffect(() => {
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/categories/${params?.id}`)
                .then((res) => setData(res?.data.categories))
                .catch((err) => console.log());
        };
        load();
    }, []);

    const create = (values) => {
        // eslint-disable-next-line camelcase
        const { name, status, display_order } = values;
        const st = showStatus[status];
        dispatch(addCategory({ id: 0, name, display_order, status: st, history }));
    };

    const edit = (values) => {
        // eslint-disable-next-line camelcase
        const { name, display_order, status } = values;
        const st = showStatus[status];
        const id = data?.id;
        dispatch(updateCategory({ id, name, display_order, status: st }));
        history.push('/services-categories');
    };

    const remove = () => {
        const id = data?.id;
        dispatch(deleteCategory(id));
        history.push('/services-categories');
    };

    const breadCrumList = [
        {
            name: 'Categorías',
            url: '/services-categories',
        },
        {
            name: data?.name || 'Añadir categoría',
            url: '/services-categories',
        },
    ];

    return (
        <DashBoard>
            <BreadCrumb title="Categoría" list={breadCrumList} />
            <div className="grid grid-cols-6 flex flex-row shadow overflow-hidden bg-white p-3">
                <div className="w-full  px-4 pt-3 col-span-6 sm:col-span-6">
                    <form onSubmit={data ? handleSubmit(edit) : handleSubmit(create)}>
                        <div className="grid grid-cols-6 flex flex-row">
                            <div className="col-span-6  sm:col-span-5">
                                <CategoryForm
                                    data={data}
                                    setValue={setValue}
                                    getValue={getValues}
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-1 mt-8 ">
                                <div className=" flex flex-col  text-right sm:px-6 px-6">
                                    {data ? (
                                        <>
                                            <button
                                                type="submit"
                                                className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-2 justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-700  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => remove()}
                                            >
                                                Eliminar
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </DashBoard>
    );
}
