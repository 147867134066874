import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import Search from '../../components/Search';
import BreadCrumb from '../../components/BreadCrumb';

import DataCRUD from '../../components/DataCRUD';
import { interest } from '../../redux/selectors';

import InterestForm from '../../components/Forms/InterestForm';
import DeleteForm from './DeleteForm';

import { getAllInterests } from '../../redux/Interests/interests.actions';

const columnsModel = [
    {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: (row) => row?.description,
        sortable: true,
        maxWidth: '200px',
    },
    {
        name: 'Estado',
        cell: (row) =>
            row?.status === 1 ? (
                <p data-tag="allowRowEvents">Activo</p>
            ) : (
                <p data-tag="allowRowEvents">Inactivo</p>
            ),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => row.status === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => row.status === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Intereses',
        url: '/interests',
    },
];

export default function Interest() {
    const interestState = useSelector(interest);
    const INTEREST = interestState.interests;
    const [searchData, setSearchData] = useState(interestState.interests);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSearch = (text) => {
        setSearchData((prev) =>
            prev.filter((u) => u.name.toLowerCase().includes(text.toLowerCase())),
        );
    };

    useEffect(() => {
        setSearchData(interestState.interests);
    }, [interestState.interests]);

    useEffect(() => {
        dispatch(getAllInterests(history));
    }, [interestState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Intereses" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={INTEREST}
                />
            </div>
            <DataCRUD
                url="interests"
                title="Intereses"
                data={searchData}
                columns={columnsModel}
                form={InterestForm}
                removeForm={DeleteForm}
                canCreate
                canDelete
                canEdit
            />
        </DashBoard>
    );
}
