/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';
import { formatValue } from '../../utils/Utils';
import { loader } from '../../redux/selectors';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function UserShowDetails() {
    const params = useParams();
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { show: isLoading } = useSelector(loader);

    useLayoutEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/transactions/${params?.id}`)
                .then((res) => setData(res.data))
                .catch((err) => console.log())
                .finally(() => dispatch(stopLoading()));
        };
        load();
    }, []);

    const breadCrumList = [
        {
            name: 'Transacciones',
            url: '/transactions',
        },
        {
            name: data?.id || 'Desconocido',
            url: '/transactions',
        },
    ];
    if (isLoading) return <DashBoard />;
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Transacciones" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Resumen de la transacción
                            </h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Cliente</dt>
                                    <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Link
                                            className="underline text-blue-500"
                                            to={`/users/detail/${data?.User.id}`}
                                        >
                                            {data?.User?.username}
                                        </Link>
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Servicio</dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Link
                                                className="underline text-blue-500"
                                                to={`/services-users/detail/${data?.serviceId}`}
                                            >
                                                {data?.services_user?.service?.name ||
                                                    data?.serviceId}
                                            </Link>
                                        </dd>
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Tipo de servicio
                                    </dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.services_user?.service?.name}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Prestador del servicio
                                    </dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.services_user?.userID}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Total</dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {formatValue(data?.total)}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Método de pago
                                    </dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.payment_type.name}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Estado del pago
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.status}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Descripción
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.reference_services}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de pago
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Date(data?.payInfo?.transaction_date).toLocaleString()}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Fecha de creación
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {new Date(data?.createdAt).toLocaleString()}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Esta transacción no se encuentra registrada
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
