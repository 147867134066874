import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const userServicesDefaultData = {
	id:0,
	userId: 0,
	stateServiceId: 0,
	servicesId: 0,
	value: 0.0,
	description: '',
	dateService: new Date().toISOString(),
	createdAt: new Date().toISOString()
} */

const initialState = {
    userServices: [],
    error: false,
    message: null,
    id_log: '',
    imagesAdded: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_USER_SERVICES_SUCCESS:
            return {
                ...state,
                error: false,
                userServices: [...action.payload.userServices],
            };
        case ActionTypes.LOAD_USER_SERVICES_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload.message,
            };
        case ActionTypes.ADD_USER_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_USER_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_USER_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_USER_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_USER_SERVICE_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_USER_SERVICE_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_IMAGES_TO_SERVICE:
            return {
                ...state,
                imagesAdded: [...state.imagesAdded, action.payload.link],
            };
        case ActionTypes.CLEAR_IMAGES_TO_SERVICE:
            return { ...state, imagesAdded: [] };
        default:
            return state;
    }
};

export default reducer;
