import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import { services } from '../../redux/selectors';

import DataCRUD from '../../components/DataCRUD';
import ServiceForm from '../../components/Forms/ServiceForm';

import DeleteForm from './DeleteForm';

import { getAllServices } from '../../redux/Services/services.actions';

const columnsModel = [
    {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: (row) => row?.description,
        sortable: true,
        maxWidth: '200px',
    },
    {
        name: 'Fecha de creación',
        selector: (row) => new Date(row?.createdAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Servicios',
        url: '/services',
    },
];

function Services() {
    const serviceState = useSelector(services);
    const SERVICES = serviceState.services;
    const [searchData, setSearchData] = useState(serviceState.services);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = useMemo(
        () => (text) => {
            setSearchData((prev) =>
                prev.filter((u) => u.name.toLowerCase().includes(text.toLowerCase())),
            );
        },
        [searchData],
    );

    useEffect(() => {
        setSearchData(serviceState.services);
    }, [serviceState.services]);

    useEffect(() => {
        dispatch(getAllServices(history));
    }, [serviceState.id_log]);

    return (
        <DashBoard>
            <BreadCrumb title="Servicios" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={SERVICES}
                />
            </div>
            <DataCRUD
                url="services"
                title="Tipos de servicio"
                data={searchData}
                columns={columnsModel}
                form={ServiceForm}
                removeForm={DeleteForm}
                canDelete
                canEdit
            />
        </DashBoard>
    );
}

export default Services;
