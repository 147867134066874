import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

/* const citiesDefaultData = {
	id:0,
	name: '',
	description: '',
	status: false,
	createdAt: new Date().toISOString()
} */

const initialState = {
    cities: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_CITIES_SUCCESS:
            return { ...state, error: false, cities: [...action.payload.cities] };
        case ActionTypes.LOAD_CITIES_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.ADD_CITY_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.ADD_CITY_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_CITY_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.DELETE_CITY_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_CITY_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.UPDATE_CITY_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        default:
            return state;
    }
};

export default reducer;
