import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllCountries =
    (history = null, limit = 60) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/countries?offset=&limit=${limit}`)
            .then((res) => {
                const { countries } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_COUNTRIES_SUCCESS,
                    payload: {
                        countries,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_COUNTRIES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addCountry =
    ({ name, code, status, currency, iso, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            code,
            status,
            currency,
            iso,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/countries', data)
            .then((res) => {
                const response = res.data;
                const message = 'País agregado correctamente';
                dispatch(stopLoading());
                history?.push('/location-countries');
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_COUNTRY_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_COUNTRY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteCountry =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/countries/${id}`)
            .then((res) => {
                const message = 'País eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_COUNTRY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_COUNTRY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateCountry =
    ({ id, name, code, currency, iso, status, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            code,
            status,
            currency,
            iso,
        };
        axiosInstance()
            .put(`/countries/${id}`, newData)
            .then((res) => {
                const message = 'País actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_COUNTRY_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_COUNTRY_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
