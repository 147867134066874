import React from 'react';
import Dashboard from '../../components/DashBoard';
import PaymentSettings from '../../components/PaymentSettings';

const Settings = () => {
    return (
        <Dashboard>
            <div className="p-5 bg-white">
                <h1 className="text-3xl font-bold text-center pb-3">Configuraciones</h1>
                <PaymentSettings />
            </div>
        </Dashboard>
    );
};

export default Settings;
