/* eslint-disable no-shadow */
import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllServices =
    (history = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get('/services?offset=0&limit=1000000')
            .then((res) => {
                const { results } = res.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_SERVICES_SUCCESS,
                    payload: {
                        services: results,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_SERVICES_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const addService =
    ({ name, description, history = null }) =>
    (dispatch) => {
        const data = {
            name,
            description,
        };
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .post('/services', data)
            .then((res) => {
                const response = res.data;
                const message = 'Servicio agregado correctamente';
                dispatch(stopLoading());
                history?.push('/services');
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.ADD_SERVICE_SUCCESS,
                    payload: { message },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.ADD_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteService =
    (id, history = null) =>
    (dispatch, prevState) => {
        axiosInstance(history, dispatch)
            .delete(`/services/${id}`)
            .then((res) => {
                const message = 'Servicio eliminado satisfactoriamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'warning' }));
                return dispatch({
                    type: ActionTypes.DELETE_SERVICE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.DELETE_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const updateService =
    ({ id, name, description, imageUrl, history = null }) =>
    (dispatch) => {
        const newData = {
            name,
            description,
            imageUrl,
        };
        axiosInstance()
            .put(`/services/${id}`, newData)
            .then((res) => {
                const message = 'Servicio actualizado correctamente';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'success' }));
                return dispatch({
                    type: ActionTypes.UPDATE_SERVICE_SUCCESS,
                    payload: {
                        message,
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.msg;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.UPDATE_SERVICE_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };
