const types = {
    // AUTH
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_ERROR: 'GET_PROFILE_SUCCESS',
    // users module
    LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
    LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_ERROR: 'ADD_USER_ERROR',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_SUCCESS',
    ADD_IMAGES_TO_SERVICE: 'ADD_IMAGES_TO_USER',
    CLEAR_IMAGES_TO_SERVICE: 'CLEAR_IMAGES_TO_USER',

    // roles module
    LOAD_ROLES_SUCCESS: 'LOAD_ROLES_SUCCESS',
    LOAD_ROLES_ERROR: 'LOAD_ROLES_ERROR',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    ADD_ROLE_ERROR: 'ADD_ROLE_ERROR',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_ERROR: 'UPDATE_ROLE_SUCCESS',

    // interests module
    LOAD_INTERESTS_SUCCESS: 'LOAD_INTERESTS_SUCCESS',
    LOAD_INTERESTS_ERROR: 'LOAD_INTERESTS_ERROR',
    ADD_INTEREST_SUCCESS: 'ADD_INTERESTS_SUCCESS',
    ADD_INTEREST_ERROR: 'ADD_INTEREST_ERROR',
    DELETE_INTEREST_SUCCESS: 'DELETE_INTERESTS_SUCCESS',
    DELETE_INTEREST_ERROR: 'DELETE_INTEREST_ERROR',
    UPDATE_INTEREST_SUCCESS: 'UPDATE_INTERESTS_SUCCESS',
    UPDATE_INTEREST_ERROR: 'UPDATE_INTEREST_ERROR',

    // interest users

    LOAD_INTEREST_USERS_SUCCESS: 'LOAD_INTEREST_USERS_SUCCESS',
    LOAD_INTEREST_USERS_ERROR: 'LOAD_INTEREST_USERS_ERROR',
    ADD_INTEREST_USER_SUCCESS: 'ADD_INTEREST_USER_SUCCESS',
    ADD_INTEREST_USER_ERROR: 'ADD_INTEREST_USER_ERROR',
    DELETE_INTEREST_USER_SUCCESS: 'DELETE_INTEREST_USER_SUCCESS',
    DELETE_INTEREST_USER_ERROR: 'DELETE_INTEREST_USER_ERROR',
    UPDATE_INTEREST_USER_SUCCESS: 'UPDATE_INTEREST_USER_SUCCESS',
    UPDATE_INTEREST_USER_ERROR: 'UPDATE_INTEREST_USER_SUCCESS',

    // services module
    LOAD_SERVICES_SUCCESS: 'LOAD_SERVICES_SUCCESS',
    LOAD_SERVICES_ERROR: 'LOAD_SERVICES_ERROR',
    ADD_SERVICE_SUCCESS: 'ADD_SERVICE_SUCCESS',
    ADD_SERVICE_ERROR: 'ADD_SERVICE_ERROR',
    DELETE_SERVICE_SUCCESS: 'DELETE_SERVICE_SUCCESS',
    DELETE_SERVICE_ERROR: 'DELETE_SERVICE_ERROR',
    UPDATE_SERVICE_SUCCESS: 'UPDATE_SERVICE_SUCCESS',
    UPDATE_SERVICE_ERROR: 'UPDATE_SERVICE_ERROR',

    // state services
    LOAD_STATE_SERVICES_SUCCESS: 'LOAD_STATE_SERVICES_SUCCESS',
    LOAD_STATE_SERVICES_ERROR: 'LOAD_STATE_SERVICES_ERROR',
    ADD_STATE_SERVICE_SUCCESS: 'ADD_STATE_SERVICE_SUCCESS',
    ADD_STATE_SERVICE_ERROR: 'ADD_STATE_SERVICE_ERROR',
    DELETE_STATE_SERVICE_SUCCESS: 'DELETE_STATE_SERVICE_SUCCESS',
    DELETE_STATE_SERVICE_ERROR: 'DELETE_STATE_SERVICE_ERROR',
    UPDATE_STATE_SERVICE_SUCCESS: 'UPDATE_STATE_SERVICE_SUCCESS',
    UPDATE_STATE_SERVICE_ERROR: 'UPDATE_STATE_SERVICE_ERROR',

    // user services
    LOAD_USER_SERVICES_SUCCESS: 'LOAD_USER_SERVICES_SUCCESS',
    LOAD_USER_SERVICES_ERROR: 'LOAD_USER_SERVICES_ERROR',
    ADD_USER_SERVICE_SUCCESS: 'ADD_USER_SERVICE_SUCCESS',
    ADD_USER_SERVICE_ERROR: 'ADD_USER_SERVICE_ERROR',
    DELETE_USER_SERVICE_SUCCESS: 'DELETE_USER_SERVICE_SUCCESS',
    DELETE_USER_SERVICE_ERROR: 'DELETE_USER_SERVICE_ERROR',
    UPDATE_USER_SERVICE_SUCCESS: 'UPDATE_USER_SERVICE_SUCCESS',
    UPDATE_USER_SERVICE_ERROR: 'UPDATE_USER_SERVICE_ERROR',

    // matchs module
    LOAD_MATCHS_SUCCESS: 'LOAD_MATCHS_SUCCESS',
    LOAD_MATCHS_ERROR: 'LOAD_MATCHS_ERROR',
    ADD_MATCH_SUCCESS: 'ADD_MATCH_SUCCESS',
    ADD_MATCH_ERROR: 'ADD_MATCH_ERROR',
    DELETE_MATCH_SUCCESS: 'DELETE_MATCH_SUCCESS',
    DELETE_MATCH_ERROR: 'DELETE_MATCH_ERROR',
    UPDATE_MATCH_SUCCESS: 'UPDATE_MATCH_SUCCESS',
    UPDATE_MATCH_ERROR: 'UPDATE_MATCH_SUCCESS',

    // payment types
    LOAD_PAYMENT_TYPES_SUCCESS: 'LOAD_PAYMENT_TYPES_SUCCESS',
    LOAD_PAYMENT_TYPES_ERROR: 'LOAD_PAYMENT_TYPES_ERROR',
    ADD_PAYMENT_TYPE_SUCCESS: 'ADD_PAYMENT_TYPE_SUCCESS',
    ADD_PAYMENT_TYPE_ERROR: 'ADD_PAYMENT_TYPE_ERROR',
    DELETE_PAYMENT_TYPE_SUCCESS: 'DELETE_PAYMENT_TYPE_SUCCESS',
    DELETE_PAYMENT_TYPE_ERROR: 'DELETE_PAYMENT_TYPE_ERROR',
    UPDATE_PAYMENT_TYPE_SUCCESS: 'UPDATE_PAYMENT_TYPE_SUCCESS',
    UPDATE_PAYMENT_TYPE_ERROR: 'UPDATE_PAYMENT_TYPE_ERROR',

    // transactions
    LOAD_TRANSACTIONS_SUCCESS: 'LOAD_TRANSACTIONS_SUCCESS',
    LOAD_TRANSACTIONS_ERROR: 'LOAD_TRANSACTIONS_ERROR',
    ADD_TRANSACTION_SUCCESS: 'ADD_TRANSACTION_SUCCESS',
    ADD_TRANSACTION_ERROR: 'ADD_TRANSACTION_ERROR',
    DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
    DELETE_TRANSACTION_ERROR: 'DELETE_TRANSACTION_ERROR',
    UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
    UPDATE_TRANSACTION_ERROR: 'UPDATE_TRANSACTION_ERROR',

    // wallet
    LOAD_WALLETS_SUCCESS: 'LOAD_WALLETS_SUCCESS',
    LOAD_WALLETS_ERROR: 'LOAD_WALLETS_ERROR',
    ADD_WALLET_SUCCESS: 'ADD_WALLET_SUCCESS',
    ADD_WALLET_ERROR: 'ADD_WALLET_ERROR',
    DELETE_WALLET_SUCCESS: 'DELETE_WALLET_SUCCESS',
    DELETE_WALLET_ERROR: 'DELETE_WALLET_ERROR',
    UPDATE_WALLET_SUCCESS: 'UPDATE_WALLET_SUCCESS',
    UPDATE_WALLET_ERROR: 'UPDATE_WALLET_ERROR',

    // wallet transaction confirmed
    LOAD_WALLET_TRASACTIONS_APPROVED_SUCCESS: 'LOAD_WALLET_TRASACTIONS_APPROVED_SUCCESS',
    LOAD_WALLET_TRASACTIONS_APPROVED_ERROR: 'LOAD_WALLET_TRASACTIONS_APPROVED_ERROR',

    // wallet transaction rejects
    LOAD_WALLET_TRASACTIONS_REJECT_SUCCESS: 'LOAD_WALLET_TRASACTIONS_REJECT_SUCCESS',
    LOAD_WALLET_TRASACTIONS_REJECT_ERROR: 'LOAD_WALLET_TRASACTIONS_REJECT_ERROR',

    // wallet transaction PENDING
    LOAD_WALLET_TRASACTIONS_PENDING_SUCCESS: 'LOAD_WALLET_TRASACTIONS_PENDING_SUCCESS',
    LOAD_WALLET_TRASACTIONS_PENDING_ERROR: 'LOAD_WALLET_TRASACTIONS_PENDING_ERROR',
    UPDATE_WALLET_TRASACTIONS_PENDING_SUCCESS: 'UPDATE_WALLET_TRASACTIONS_PENDING_SUCCESS',
    UPDATE_WALLET_TRASACTIONS_PENDING_ERROR: 'UPDATE_WALLET_TRASACTIONS_PENDING_ERROR',
    // offerts
    LOAD_OFFERS_SUCCESS: 'LOAD_OFFERS_SUCCESS',
    LOAD_OFFERS_ERROR: 'LOAD_OFFERS_ERROR',
    ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
    ADD_OFFER_ERROR: 'ADD_OFFER_ERROR',
    DELETE_OFFER_SUCCESS: 'DELETE_OFFER_SUCCESS',
    DELETE_OFFER_ERROR: 'DELETE_OFFER_ERROR',
    UPDATE_OFFER_SUCCESS: 'UPDATE_OFFER_SUCCESS',
    UPDATE_OFFER_ERROR: 'UPDATE_OFFER_SUCCESS',

    // collections

    LOAD_COLLECTIONS_SUCCESS: 'LOAD_COLLECTIONS_SUCCESS',
    LOAD_COLLECTIONS_ERROR: 'LOAD_COLLECTIONS_ERROR',
    ADD_COLLECTION_SUCCESS: 'ADD_COLLECTION_SUCCESS',
    ADD_COLLECTION_ERROR: 'ADD_COLLECTION_ERROR',
    DELETE_COLLECTION_SUCCESS: 'DELETE_COLLECTION_SUCCESS',
    DELETE_COLLECTION_ERROR: 'DELETE_COLLECTION_ERROR',
    UPDATE_COLLECTION_SUCCESS: 'UPDATE_COLLECTION_SUCCESS',
    UPDATE_COLLECTION_ERROR: 'UPDATE_COLLECTION_SUCCESS',

    // cities

    LOAD_CITIES_SUCCESS: 'LOAD_CITIES_SUCCESS',
    LOAD_CITIES_ERROR: 'LOAD_CITIES_ERROR',
    ADD_CITY_SUCCESS: 'ADD_CITY_SUCCESS',
    ADD_CITY_ERROR: 'ADD_CITY_ERROR',
    DELETE_CITY_SUCCESS: 'DELETE_CITY_SUCCESS',
    DELETE_CITY_ERROR: 'DELETE_CITY_ERROR',
    UPDATE_CITY_SUCCESS: 'UPDATE_CITY_SUCCESS',
    UPDATE_CITY_ERROR: 'UPDATE_CITY_ERROR',

    // countries

    LOAD_COUNTRIES_SUCCESS: 'LOAD_COUNTRIES_SUCCESS',
    LOAD_COUNTRIES_ERROR: 'LOAD_COUNTRIES_ERROR',
    ADD_COUNTRY_SUCCESS: 'ADD_COUNTRY_SUCCESS',
    ADD_COUNTRY_ERROR: 'ADD_COUNTRY_ERROR',
    DELETE_COUNTRY_SUCCESS: 'DELETE_COUNTRY_SUCCESS',
    DELETE_COUNTRY_ERROR: 'DELETE_COUNTRY_ERROR',
    UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
    UPDATE_COUNTRY_ERROR: 'UPDATE_COUNTRY_ERROR',

    // categories
    LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
    LOAD_CATEGORIES_ERROR: 'LOAD_CATEGORIES_ERROR',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',

    // loader
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    // TOAST
    START_TOAST: 'START_TOAST',
    STOP_TOAST: 'STOP_TOAST',
};

export default types;
