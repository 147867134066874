/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function CategoryForm({ data, register, setValue, errors }) {
    useEffect(() => {
        async function load() {
            await setValue('status', data?.status === 1 ? 'active' : 'inactive');
            await setValue('name', data?.name || '');
            await setValue('display_order', data?.display_order || 1);
        }
        load();
    }, [data]);

    return (
        <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-10 gap-2">
                    <div className="col-span-10 sm:col-span-10 mt-2">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 ">
                            Nombre
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                            {...register('name', {
                                minLength: {
                                    value: 3,
                                    message: 'El nombre debe tener minimo 3 valores',
                                },
                            })}
                        />
                    </div>
                    {errors.name && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.name.message}
                        </p>
                    )}
                    <div className="col-span-10 sm:col-span-10">
                        <label
                            htmlFor="display_order"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Orden de visualización
                        </label>
                        <input
                            type="text"
                            name="display_order"
                            id="display_order"
                            autoComplete="display_order"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            {...register('display_order')}
                        />
                    </div>
                    {errors.display_order && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.display_order.message}
                        </p>
                    )}
                    <div className="col-span-5 sm:col-span-5 mt-2">
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Estado
                        </label>
                        <select
                            id="status"
                            name="status"
                            autoComplete="status"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            {...register('status')}
                        >
                            <option value="active">Activo</option>
                            <option value="inactive">Inactivo</option>
                        </select>
                    </div>
                    {errors.status && (
                        <p className="text-red-700  text-xs -mt-2 col-span-10 sm:col-span-10">
                            {errors.status.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

CategoryForm.propTypes = {
    data: PropTypes.array,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

CategoryForm.defaultProps = {
    data: [],
};
