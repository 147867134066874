import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import Search from '../../components/Search';

import DataCRUD from '../../components/DataCRUD';
import { matchs } from '../../redux/selectors';

import MatchForm from '../../components/Forms/MatchForm';

import DeleteForm from './DeleteForm';

import { getAllMatchs } from '../../redux/Matchs/matchs.actions';

const columnsModel = [
    {
        name: 'Usuario',
        selector: (row) => row?.User?.name,
        sortable: true,
    },
    {
        name: 'Servicio',
        selector: (row) => row?.ServiceMatch?.service?.name,
        sortable: true,
    },
    {
        name: 'Coincidencia',
        selector: (row) => (row?.matched === 1 ? 'SI' : 'NO'),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => row.matched === 1,
                classNames: ['text-green-400'],
            },
            {
                when: (row) => row.matched === 0,
                classNames: ['text-red-500'],
            },
        ],
    },
    {
        name: 'Fecha de emparejamiento',
        selector: (row) => new Date(row?.matchedAt).toLocaleString(),
        sortable: true,
    },
];

const breadCrumList = [
    {
        name: 'Coincidencias',
        url: '/matchs',
    },
];

export default function Matchs() {
    const matchState = useSelector(matchs);
    const MATCHES = matchState.matchs;
    const [searchData, setSearchData] = useState(matchState.matchs);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSearch = (text) => {
        console.log(text);
        setSearchData((prev) =>
            MATCHES.filter((u) => u.User?.username?.toLowerCase().includes(text?.toLowerCase())),
        );
    };

    useEffect(() => {
        setSearchData(matchState.matchs);
    }, [matchState.matchs]);

    useEffect(() => {
        dispatch(getAllMatchs(history));
    }, [matchState.id_log]);

    console.log();

    return (
        <DashBoard>
            <BreadCrumb title="Coincidencias" list={breadCrumList} />
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                <Search
                    placeholder="Buscar por nombre de usuario"
                    handleSearch={handleSearch}
                    data={searchData}
                    setData={setSearchData}
                    initialData={MATCHES}
                />
            </div>
            <DataCRUD
                url="matchs"
                title="Coincidencias"
                data={searchData}
                columns={columnsModel}
                form={MatchForm}
                removeForm={DeleteForm}
                show
            />
        </DashBoard>
    );
}
