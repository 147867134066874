/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable  react/no-unescaped-entities */
import React, { useState, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';

import UserForm from '../../components/Forms/UserForm';

import axiosInstance from '../../helpers/axiosInstance';
import { addUser, updateUser, deleteUser, updateUserRole } from '../../redux/Users/users.actions';
import { getAllCities } from '../../redux/Cities/cities.actions';
import { getAllCountries } from '../../redux/Countries/countries.actions';
import { getAllRoles } from '../../redux/Roles/roles.actions';

export default function UserDetail() {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    useLayoutEffect(() => {
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/users/${params?.id}`)
                .then((res) => setData(res.data.results))
                .catch(() => console.log(''));
        };
        load();
        dispatch(getAllCities(history));
        dispatch(getAllCountries(history));
        dispatch(getAllRoles(history));
    }, []);

    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        control,
    } = useForm();

    const showStatus = {
        active: 1,
        blocked: 0,
    };

    const create = (values) => {
        const {
            username,
            email,
            name,
            lastName,
            description,
            birthday,
            cities,
            countries,
            status,
            password,
            mobilePhone,
            gender,
            rol,
        } = values;
        const st = showStatus[status];
        const formatDate = Date(birthday);
        const rolId = rol?.value;

        dispatch(
            addUser({
                username,
                name,
                lastName,
                email,
                mobilePhone,
                description,
                birthday: formatDate,
                status: st,
                cityId: cities?.value,
                countryId: countries?.value,
                password,
                gender,
                rolId,
                history,
            }),
        );
    };

    const edit = (values) => {
        const {
            username,
            email,
            name,
            lastName,
            description,
            cities,
            countries,
            status,
            birthday,
            mobilePhone,
            gender,
            rol,
        } = values;
        const st = showStatus[status];
        const id = data?.id;

        const formatDate = Date(birthday);

        const roles = data?.Roles?.map((role) => role?.name);
        console.log(rol);

        if (!(rol in roles)) {
            dispatch(updateUserRole({ userId: data?.id, rolesId: rol?.value, history }));
        }

        dispatch(
            updateUser({
                id,
                username,
                name,
                lastName,
                email,
                gender,
                mobilePhone,
                description,
                status: st,
                cityId: cities?.value,
                countryId: countries?.value,
                birthday: formatDate,
            }),
        );
    };

    const remove = () => {
        const id = data?.id;
        dispatch(deleteUser(id));
        history.push('/users');
    };

    const breadCrumList = [
        {
            name: 'Usuarios',
            url: '/users',
        },
        {
            name: data?.username || 'Añadir usuario',
            url: '/users',
        },
    ];

    return (
        <DashBoard>
            <BreadCrumb title="Usuarios" list={breadCrumList} />
            <div className="grid grid-cols-6 flex flex-row shadow bg-white p-3">
                <div className="w-full  px-4 pt-3 col-span-6 sm:col-span-6">
                    <form onSubmit={data ? handleSubmit(edit) : handleSubmit(create)}>
                        <div className="grid grid-cols-6 flex flex-row">
                            <div className="col-span-6 sm:col-span-5">
                                <UserForm
                                    data={data}
                                    setValue={setValue}
                                    getValue={getValues}
                                    handleSubmit={handleSubmit}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    watch={watch}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-1 mt-8 ">
                                <div className=" flex flex-col bg-gray-50 text-right sm:px-6 px-6">
                                    {data ? (
                                        <>
                                            <button
                                                type="submit"
                                                className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-2 justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-700  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => remove()}
                                            >
                                                Eliminar
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="justify-center py-2 px-4 border  shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </DashBoard>
    );
}
