import React from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateWalletTransaction } from '../../redux/Wallets/wallets.actions';

function RejectModal({ show, transactionId, setShow }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const closeModal = () => {
        setShow((prev) => !prev);
    };

    const submit = (values) => {
        const { description } = values;
        dispatch(
            updateWalletTransaction({
                id: transactionId,
                description,
                status: 'REJECTED',
                history,
                closeModal,
            }),
        );
    };
    return (
        <Dialog open={show} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="relative bg-white rounded max-w-sm mx-auto p-5">
                    <Dialog.Title className="font-bold text-2xl">Confirma la acción</Dialog.Title>
                    <div className="ml-4 w-100">
                        <h2 className="font-medium pt-4 text-lg ">
                            ¿Estás seguro de <span className="font-bold">rechazar</span> esta
                            transacción?
                        </h2>
                        <form onSubmit={handleSubmit(submit)}>
                            <textarea
                                rows={5}
                                placeholder="Motivo de aprobación"
                                name="description"
                                id="description"
                                autoComplete="description"
                                className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                {...register('description', {
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido',
                                    },
                                    minLength: {
                                        value: 3,
                                        message: 'El nombre debe tener minimo 3 valores',
                                    },
                                })}
                            />
                            {errors.description && (
                                <p className="text-red-700  text-xs mt-1 col-span-10 sm:col-span-10">
                                    {errors.description.message}
                                </p>
                            )}
                            <div className="flex mt-2">
                                <button
                                    className="text-gray-800 mt-2 w-64 text-black p-3 bg-white"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className="btn btn-primary ml-4 shadow-lg mt-2 w-64 text-white self-end p-3 bg-red-600"
                                    type="submit"
                                >
                                    Rechazar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default RejectModal;
