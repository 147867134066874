/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';

import { PaperClipIcon, ChevronUpIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { EyeIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';

import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashBoard from '../../components/DashBoard';
import BreadCrumb from '../../components/BreadCrumb';
import axiosInstance from '../../helpers/axiosInstance';
import { formatValue } from '../../utils/Utils';
import { loader } from '../../redux/selectors';
import { startLoading, stopLoading } from '../../redux/Loader/loader.actions';

export default function WalletShowDetail() {
    const params = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { show: isLoading } = useSelector(loader);

    useLayoutEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/wallet_user_transactions/byId/${params?.id}`)
                .then((res) => setData(res.data?.wallet))
                .catch((err) => console.log('err'))
                .finally(() => dispatch(stopLoading()));
        };
        load();
    }, []);

    const breadCrumList = [
        {
            name: 'Transacciones',
            url: '/wallets-transactions-approved',
        },
        {
            name: data?.user?.username || 'Desconocido',
            url: '/users',
        },
    ];
    if (isLoading) return <DashBoard />;
    return (
        <DashBoard>
            {data ? (
                <>
                    <BreadCrumb title="Usuarios" list={breadCrumList} />
                    <div className="bg-white shadow-lg overflow:hidden sm:rounded-lg pb-3">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Detalle de la transacción
                            </h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Usuario</dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Link
                                                className="underline text-blue-500"
                                                to={`/users/detail/${data?.user?.id}`}
                                            >
                                                {data?.user?.username}
                                            </Link>
                                        </dd>
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Servicio de usuario
                                    </dt>
                                    <dd className="mt-1 capitalize text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Link
                                                className="underline text-blue-500"
                                                to={`/services-users/detail/${data?.services_user?.id}`}
                                            >
                                                {data?.services_user?.service?.name ||
                                                    data?.serviceId}
                                            </Link>
                                        </dd>
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Monto</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {formatValue(data?.amount)}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Estados</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.status}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Descripción
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.description}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-lg p-10">
                    <h4 className="text-center text-red-600 text-xl">
                        Esta wallet no se encuentra registrada
                    </h4>
                </div>
            )}
        </DashBoard>
    );
}
